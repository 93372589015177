import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/public/home.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import myImage from './original-12484d8005a15a7369c7e03fcb451758.png';

import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {
	
  useEffect(() => {
    document.title = 'Aadow — Powerful and Advanced Email Marketing Software & Platform';

    const options = {
      threshold: 0.7 // Trigger shrinking when 70% of the stack item is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('shrink'); // Expand the current item
        } else {
          entry.target.classList.add('shrink'); // Shrink the previous item
        }
      });
    }, options);

    document.querySelectorAll('.stack-item').forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  

  return (
	<>
		<HelmetProvider>
			<Helmet>
			{/* Basic SEO */}
			<title>Aadow — Powerful and Advanced Email Marketing Software & Platform</title>
			<meta
				name="title"
				content="Aadow — Powerful and Advanced Email Marketing Software & Platform"
			/>
			<meta
				name="description"
				content="Boost your email campaigns with Aadow, the advanced email marketing software designed for businesses of all sizes. Create, automate, and track impactful campaigns with ease. Try Aadow today for better engagement and results."
			/>
			<meta
				name="keywords"
				content="Email Marketing Platform, Email Marketing Software, Free Email Marketing, Free Email Marketing Credits, Email Campaigns, Best Email Campaign Tools, Advanced Email Marketing Solutions, Automated Email Marketing, Email Campaign Management Software, Email Automation Platform, AMP Email, AMP Mail, Business Email Campaign Software, Top Email Marketing Platforms 2024, Email Campaign Tracking Tools, Free Email Marketing Tool, Mailchimp, Mailjet, Brevo, Zoho Email, Sendgrid, Mailgun, Mailmodo, Alternatives of Mailchimp, Alternatives of Mailjet, Alternatives of Zoho Email, Alternatives of Sendgrid, Alternative of, Alternatives of Mailgun, Alternative of Yamm, Mail Sender, Bulk Email Sending, Transactional Email, Marketing Email, Promotional Email, Newsletter Email, OTP Email"
			/>

			{/* Open Graph / Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content="https://www.aadow.com" />
			<meta
				property="og:title"
				content="Aadow — Powerful and Advanced Email Marketing Software & Platform"
			/>
			<meta
				property="og:description"
				content="Boost your email campaigns with Aadow, the advanced email marketing software designed for businesses of all sizes. Create, automate, and track impactful campaigns with ease. Try Aadow today for better engagement and results."
			/>
			<meta
				property="og:image"
				content="https://www.aadow.com/imagess/Sync.png"
			/>

			{/* Twitter */}
			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content="https://www.aadow.com" />
			<meta
				property="twitter:title"
				content="Aadow — Powerful and Advanced Email Marketing Software & Platform"
			/>
			<meta
				property="twitter:description"
				content="Boost your email campaigns with Aadow, the advanced email marketing software designed for businesses of all sizes. Create, automate, and track impactful campaigns with ease. Try Aadow today for better engagement and results."
			/>
			<meta
				property="twitter:image"
				content="https://www.aadow.com/imagess/Sync.png"
			/>
			</Helmet>
		
		</HelmetProvider>
    <div className="home-body">
      <TopBar />
      <div 
	  className="top-area" 
	  style={{
		backgroundImage: 'url(/imagess/top-bg-final.png)',
		backgroundSize: 'cover', // Optional: Adjust the background size
		backgroundPosition: 'center', // Optional: Center the background image
	  }}
	  >
        <h1 className="main-heading">Emails That Do More: One Click, Endless Possibilities</h1>
        <h6 className="main-subheading">
          Watch Your Campaigns Talk, Engage, and Convert with Aadow.
        </h6>
        <div className="button-area text-center">
          <Link to="/signup"><button className="primary-button">Get Started</button></Link>
          <Link to="/Pricing"><button type="button" className="secondary-button">See Pricing</button></Link>
          <p>Get Started for free. No credit card required</p>
        </div>
        <div className="blocks-container">
		  <div className="block block1">
			<img src="imagess/working-home-hero.jpg"></img>
		  </div>
          <div className="block block2">
		  	<i className='bi bi-send-check'></i>
			<h2 className='heading-block'>10000+</h2>
			<p className='subheading-block'>Campaigns sends every minute</p>
		  </div>
          <div className="block block3">
			
			<i className='bi bi-palette'></i>
		  	<h2 className='heading-block'>40,000+</h2>
			<h2 className='description-block'>Designed Email Templates</h2>
		  </div>
          <div className="block block4">
		  	<i className='bi bi-stars'></i>
		  	<h2 className='heading-block'>AIM</h2>
			<p className='subheading-block'>Unlock the Power of AMP Emails</p>
			
		  </div>
          <div className="block block5">
		  	<i className='bi bi-speedometer2'></i>
			<h1 className='heading-block'>Target the Best Prospects with Precision Segmentation</h1>
		  </div>
        </div>
      </div>
		
	
	  <img src="imagess/Sync.png" className="w-100" />	
		
	 <div className="info-area">	
		  <h2 className="stack-container-heading">The Ultimate Interactive Email Marketing Platform</h2>
		  <div className="stack-container">
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/1.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Supercharge Your Email Marketing with AI</h2>
				  <h6 className="infoblock-subheading">Seamlessly Generate Compelling Content with Intelligent Assistance</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> AI-Enhanced Subject Lines</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced Pre-Headers</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Effortless Email Content</h6>
				  </div>
				</div>
			  </div>
			</div>

			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/2.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Craft Stunning Templates with Ease</h2>
				  <h6 className="infoblock-subheading">Unleash Your Creativity with Our Comprehensive Design Tools</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Drag-and-Drop Simplicity</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Custom HTML Design</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> WYSIWYG Flexibility</h6>
				  </div>
				</div>
			  </div>
			</div>

			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/3.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Optimize Your Campaigns with Spam Indicator Insights</h2>
				  <h6 className="infoblock-subheading">Ensure Your Emails Land in the Inbox, Not the Spam Folder</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Real-Time Spam Check</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Actionable Insights</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Campaign Confidence</h6>
				  </div>
				</div>
			  </div>
			</div>

			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/4.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading"> Product/Brand Showcase with AIM</h2>
				  <h6 className="infoblock-subheading">Create Interactive Carousels to Highlight Your Brand with Aadow Interactive Mail</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Dynamic Product Displays</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Instant Website Experience</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced User Engagement</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/8.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Gamify Your Emails</h2>
				  <div className="info-block-bullets">
				  <h6 className="infoblock-subheading">Add Fun and Engagement with Interactive Gamify AIM</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Spin the Wheel</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Scratch the Coupon</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Quiz Challanges</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/6.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Collect Feedback and Surveys</h2>
				  <h6 className="infoblock-subheading">Gather Valuable Insights through your email campaigns</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Instant Feedback Collection</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Real-Time Responses</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced User Experience</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/7.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Lead Collection Made Easy with One Click</h2>
				  <h6 className="infoblock-subheading">Capture Leads Instantly with Built-In Forms</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Embedded Forms</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Instant Data Collection</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Increase Conversion Rates</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/5.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Uncover Your Most Engaged Audience with Precision</h2>
				  <h6 className="infoblock-subheading">Target Your Campaigns with Confidence Using Aadow's Audience Insights</h6>
				  
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Identify True Interest</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Data-Driven Targeting</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced Campaign Effectiveness</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			
		  </div>
      </div>
	  
	  
	  <div className="pricing-area">
		<h2 className="pricing-area-heading">Tailored Plan for your Business Needs</h2>
		<h2 className="pricing-area-subheading">Rest assured that every email will reach an inbox.</h2>
		<div className="home-pricing-area">
		<div className="row horizontalrow">
			<div className="col-12 col-md-12 col-lg-4 col-xl-4 col-price d-flex">
				<div className="pricing-col">
					<h2 className="pricing-col-heading">Basic Plan</h2>
					<h6 className="pricing-col-subheading">Experience the Basics, No Credit Card Required</h6>
					<h3 className="pricing-col-price">₹ 0 <span>/month</span></h3>
					<button type="button" className="primary-button">Get Started</button>
					
					<div className="divider-container">
					  <hr className="line" />
					  <span className="divider-text">Features</span>
					  <hr className="line" />
					</div>
					
					
					<div className="bullet-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 6,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Customizable Email Templates</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 200 Daily Sending Limit</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> No Contact Limits</h5>
					</div>
					
				</div>
			</div>
			
			<div className="col-12 col-md-12 col-lg-4 col-xl-4 col-price d-flex">
				<div className="pricing-col">
					<h2 className="pricing-col-heading">Pro Plan</h2>
					<h6 className="pricing-col-subheading">Take Your Business to the Next Level with Pro Plan</h6>
					<h3 className="pricing-col-price">₹ 2,429 <span>/month</span></h3>
					<button type="button" className="primary-button">Get Started</button>
					
					<div className="divider-container">
					  <hr className="line" />
					  <span className="divider-text">Features</span>
					  <hr className="line" />
					</div>
					
					<div className="bullet-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Everything in Basic</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 20,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Send Time Optimization</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Branding-Free Emails(No Logo)</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> AI Content Generator</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited Daily Sending</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Dedicated Priority Support</h5>
					</div>
					
				</div>
			</div>
			
			<div className="col-12 col-md-12 col-lg-4 col-xl-4 col-price d-flex">
				<div className="pricing-col">
					<h2 className="pricing-col-heading">Ultimate Plan</h2>
					<h6 className="pricing-col-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
					<h3 className="pricing-col-price">₹ 4,229 <span>/month</span></h3>
					<button type="button" className="primary-button">Get Started</button>
					
					<div className="divider-container">
					  <hr className="line" />
					  <span className="divider-text">Features</span>
					  <hr className="line" />
					</div>
					
					
					<div className="bullet-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Everything in Pro</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 20,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Team Collaboration</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> A/B Testing</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Advanced Stats</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Bulk List Uploading</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited Daily Sending</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Real-Time Assistance</h5>
					</div>
					
				</div>
			</div>
			
			
			</div>
			<div className="col-12">
				<div className="custom-plan-area">
					<h2 className="custom-plan-heading">Looking for more pricing info</h2>
					<h2 className="custom-plan-subheading">Understand the Value of Our Services. See How Our Plans Fit Your Budget</h2>
					<div className="text-center">
					<Link to="/pricing"><button type="button" className="primary-button">See full pricing</button></Link>
					<Link to="/signup"><button type="button" className="secondary-button">Get Custom Plan</button></Link>
					</div>
				</div>
			</div>
			
			
			
		</div>
		
	  </div>
				<div className="contact-us-section">
					<h2 className="contact-us-section-heading">From Idea to Production in days</h2>
					<h2 className="contact-us-section-subheading">Turn Your Vision into Reality Quickly. Contact Us to Start Building Today</h2>
					<div className="text-center">
					<Link to="/contact"><button type="button" className="primary-button">Connect with us</button></Link>
					</div>
				</div>
	  
	  
	  <Footer/>
    </div>
	</>
  );
};

export default Home;
