import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CountrySelect from './../components/CountrySelect';
import '../../css/private/checkout.css';
import SideBar from './../components/SideBar';
import Cookies from 'js-cookie';

import Backdrop from "@mui/material/Backdrop";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

import CircularProgress from "@mui/material/CircularProgress";
// Import other settings components as needed

const Checkout = () => {
  
	
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
	
   const [FullPageloading, setFullPageLoading] = useState(false);
   const [timer, setTimer] = useState(3);
   const [amount, setAmount] = useState(0);
   const navigate = useNavigate();
   const [razorpayLoaded, setRazorpayLoaded] = useState(false);
   
   	const [loading, setLoading] = useState(true);
 	const [error, setError] = useState(null);
	const [PlanName, setPlanName] = useState("");
 	const [price, setprice] = useState("-----");
 	const [credits, setcredits] = useState("--------------");
 	const [PlanPriceMonthly, setPlanPriceMonthly] = useState("-----");
 	const [PlanPriceMonthlyForYear, setPlanPriceMonthlyForYear] = useState("-----");
 	const [TotalYearlyBillSubtotal, setTotalYearlyBillSubtotal] = useState("-----");

	
 	const [CheckoutSubtotal, setCheckoutSubtotal] = useState("-----");
 	const [CheckoutTax, setCheckoutTax] = useState("-----");
 	const [CheckoutFinalTOtal, setCheckoutFinalTOtal] = useState("-----");

	
 	const [CheckoutSummaryDuration, setCheckoutSummaryDuration] = useState("-----");
 	const [CheckoutSummaryAmount, setCheckoutSummaryAmount] = useState("-----");
	const CookietId = Cookies.get('current_Id');
	const CookieAccountId = Cookies.get('current_accountId');
	const CookieAccountName = Cookies.get('current_accountName');
	const CookieAccountEmail = Cookies.get('current_accountEmail');
	const CookieSelectedPlanId = Cookies.get('selected_plan_id');
	const CookieSelectedPlanType = Cookies.get('selected_plan_type');
	const [selectedPlan, setSelectedPlan] = useState(
		CookieSelectedPlanType === 'EmailTransactional' ? 'monthly' : 'yearly'
	);

	const handlePlanSelect = (plan) => {
		setSelectedPlan(plan);
		fetchPlanInfo();
	};
	useEffect(() => {
		 if (!CookieSelectedPlanId) {
			// Navigate to /select-plan using React Router's navigate function
			navigate('/select-plan');
			return;
		  }

		
		    



		fetchPlanInfo();

        const loadRazorpayScript = () => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => setRazorpayLoaded(true);
            document.body.appendChild(script);
        };
		fetchBusinessSettings();
        loadRazorpayScript();
    }, [selectedPlan]);

	
	const fetchBusinessSettings = async () => {
		try {
		  const response = await fetch(`${ServerApiDomain}/api/business/get/${CookieAccountId}`, {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json',
			},
		  });

		  if (!response.ok) {
			throw new Error('Network response was not ok');
		  }

		  const data = await response.json();
		  setCompany(data.companyName);
		  setTaxDetail(data.gst);
		  setAddress(data.address);
		  setCity(data.city);
		  setState(data.state);
		  setPostalCode(data.zip);
		  countrysetSelectedOption(data.country);
		  setBiilingName(CookieAccountName);
		  setBillingEmail(CookieAccountEmail);
		  setBillingCompany(data.companyName);
		  setBillingTax(data.gst);
		  setBillingAddress(data.address);
		  setBillingCity(data.city);
		  setBillingState(data.state);
		  setBillingPostalCode(data.zip);
		  setBillingCountry(data.country);
		  setLoading(false);
		  setBillingInfoAreashow(true);
		  
		} catch (error) {
		  setError("Fetch error:", error);
		  setLoading(false);
		  setShow(true);
		  
		}
};
	const username = 'testuser';
	const password = 'testpassword';
	const planId = CookieSelectedPlanId;
	const encodedCredentials = btoa(`${username}:${password}`);
	const fetchPlanInfo = async () => {
		try {
		  const response = await fetch(`${ServerApiDomain}/api/pricing-plans/${planId}`, {
			method: 'GET',
			headers: {
			  'Authorization': `Basic ${encodedCredentials}`,
			  'Content-Type': 'application/json',
			},
		  });

		  if (!response.ok) {
			throw new Error('Network response was not ok');
		  }
		  const data = await response.json();

		  if (selectedPlan === 'monthly') {
			setCheckoutSubtotal(data.price);

            // Set the 18% tax for the monthly plan
            const monthlyTax = (data.price * 0.18).toFixed(2);
            setCheckoutTax(monthlyTax);

            // Calculate and set the final total (price + tax)
            const monthlyFinalTotal = (parseFloat(data.price) + parseFloat(monthlyTax)).toFixed(2);
            setCheckoutFinalTOtal(monthlyFinalTotal);
			setAmount(monthlyFinalTotal);
			setCheckoutSummaryDuration(1);
			setCheckoutSummaryAmount(data.price);

		  } else if (selectedPlan === 'yearly') {
			setCheckoutSummaryDuration(12);
		    setCheckoutSubtotal(((data.price * 0.9) * 12).toFixed(2).toLocaleString());
			const yearlySubtotal = (data.price * 0.9 * 12).toFixed(2); // Yearly subtotal with discount
			setCheckoutTax((yearlySubtotal * 0.18).toFixed(2)); // 18% of the yearly subtotal
			const finalTotal = (parseFloat(yearlySubtotal) + parseFloat((yearlySubtotal * 0.18).toFixed(2))).toFixed(2);
			setCheckoutFinalTOtal(finalTotal);
			setAmount(finalTotal);
			setCheckoutSummaryAmount((data.price * 0.9));
		  }

		  setcredits((data.credits).toLocaleString());
		  setPlanName(data.planName);
		  setPlanPriceMonthly((data.price).toLocaleString('en-US'));
		  setPlanPriceMonthlyForYear((data.price * 0.9).toFixed(2).toLocaleString());
		  setTotalYearlyBillSubtotal(((data.price * 0.9) * 12).toFixed(2).toLocaleString());

		  setLoading(false);
		} catch (error) {
		  setError("Fetch error:", error);
		  setLoading(false);
		}
	};

    const handlePayment = () => {
		setFullPageLoading(true);
		if (!razorpayLoaded) {
			console.log('Razorpay SDK not loaded yet.');
			return;
		}
	
		// Get today's date for purchaseDate
		const today = new Date();
	
		// resetData: Exactly one month from today (e.g., 14th Nov to 14th Dec)
		const nextMonth = new Date(today);
		nextMonth.setMonth(today.getMonth() + 1); // Add 1 month
	
		// If the current date is the 31st and the next month has fewer days, adjust it.
		if (today.getDate() === 31) {
			nextMonth.setDate(0); // Set it to the last day of the next month
		}
	
		// expirationDate: Based on selectedPlan (monthly or yearly)
		let expirationDate;
		let PlanDuration;
		if (selectedPlan === 'monthly') {
			expirationDate = new Date(today);
			PlanDuration = "1 Month Plan";
			expirationDate.setMonth(today.getMonth() + 1); // Add 1 month for monthly plan
		} else if (selectedPlan === 'yearly') {
			expirationDate = new Date(today);
			PlanDuration = "12 Month Plan";
			expirationDate.setFullYear(today.getFullYear() + 1); // Add 1 year for yearly plan
		}
	
		// Format all dates to the required format (ISO 8601 string format)
		const resetData = nextMonth.toISOString(); // Exactly one month from today
		const purchaseDate = today.toISOString(); // Today's date
		const expirationDateStr = expirationDate.toISOString(); // Next month or next year based on selectedPlan
	
		const generateInvoiceId = () => {
			const randomNumber = Math.floor(Math.random() * 100000000); // Generates a number between 0 and 99999999
			return 'ADW-' + randomNumber.toString().padStart(8, '0'); // Ensure it's 8 digits
		};

		const options = {
			key: 'rzp_live_cMQ8RLncf3w3nu', // Replace with your Razorpay key ID
			amount: amount * 100, // Amount is in paise
			currency: 'INR',
			name: 'Aadow',
			description: 'by Horza Private Limited',
			handler: function (response) {
				// Handle success here
				console.log(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
				console.log(response);
	
				// Sample data for the API call to insertOrUpdate
				const postData = CookieSelectedPlanType === 'EmailTransactional' ? {
					username: CookieAccountId,
					usedTransactionalCredits: 0,
					totalTransactionalCredits: credits.replace(/,/g, ''),
					resetDataTransactional: resetData,  // First day of next month
					expirationDateTransactional: expirationDateStr,
					status: 'active'
				} : {
					username: CookieAccountId,
					planId: CookieSelectedPlanId,
					planName: PlanName + ' Plan',
					paymentMonthly: CheckoutSummaryAmount,
					usedEmails: 0,
					totalEmails: credits.replace(/,/g, ''),
					resetData: resetData,  // First day of next month
					purchaseDate: purchaseDate,  // Today's date
					expirationDate: expirationDateStr,
					status: 'active'
				};
	
				// Call your Spring Boot API (insertOrUpdate)
				fetch(`${ServerApiDomain}/api/myplan/insertOrUpdate`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(postData)
				})
				.then(response => response.json())
				.then(data => {
					console.log('API response for insertOrUpdate:', data);
					// After the first API call completes, call the second API for the payment details
					
					const paymentData = {
						username: CookieAccountId, // Use the account name or user info
						transactionId: response.razorpay_payment_id, // Razorpay payment ID
						amount: CheckoutFinalTOtal, // Amount of the payment
						invoiceId: generateInvoiceId(), // Use dynamic invoice ID if necessary
						payMode: 'Online', // or 'UPI', 'Wallet', etc.
						status: 'Completed',
						orderId: "response.razorpay_order_id",
						pgOrderId: "response.razorpay_order_id",
						pgPmId: "response.method", // Payment method (like 'card', 'upi', etc.)
						pgSignature: "response.signature", // Razorpay signature
						remarks: PlanName, // Customize if necessary
						invoiceName: CookieAccountName,
						invoiceCompany: BillingCompany,
						invoiceEmail: CookieAccountEmail,
						invoiceGst: BillingTax,
						invoiceAddrs: BillingAddress + ", " + BillingCity + ", " + BillingState + ", " + BillingCountry + " - " + BillingPostalCode,
						itemName: PlanName + ' Email Marketing Plan',
						itemDescription: PlanDuration,
						amountValue: CheckoutSubtotal,
						quantity: 1,
						promoCode: '', // If there's any promo code applied
						discount: "",
						taxValue: CheckoutTax, // Sample tax value
						finalTotal: CheckoutFinalTOtal, // Add tax to the total amount
						currency: 'INR', // Currency of the payment
						date: today.toISOString().split('T')[0] // Use today's date in YYYY-MM-DD format
					};
	
					// Call the second API to log payment details
					fetch(`${ServerApiDomain}/api/payments/add`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(paymentData)
					})
					.then(async (paymentResponse) => {
						if (!paymentResponse.ok) {
							const errorText = await paymentResponse.text(); // Read response as text
							throw new Error(`Error from server: ${errorText}`);
						}
						return paymentResponse.json(); // Parse JSON only if the response is OK
					})
					.then((paymentData) => {
						console.log('Payment details logged:', paymentData);
						console.log('Payment details successfully logged');
						showSnackbar('Payment Successful, Redirecting....', 'success');
						Cookies.remove('selected_plan_id');
						const countdown = setInterval(() => {
							setTimer((prevTimer) => {
								if (prevTimer <= 1) {
									clearInterval(countdown);
									navigate('/dashboard'); // Redirect to dashboard
								}
								return prevTimer - 1;
							});
						}, 2000);
					})
					.catch((paymentError) => {
						console.log('Error in second API call:'+ paymentError.message);
						console.log('There was an issue logging payment details');
						showSnackbar('Payment Failed!', 'error');
						setFullPageLoading(false);
					});
				})
				.catch(error => {
					console.log(error);
					setFullPageLoading(false);
					console.log('There was an issue with the first API call');
					showSnackbar('Payment Failed!', 'error');
				});
			},
			prefill: {
				name: CookieAccountName,
				email: CookieAccountEmail,
				contact: '',
			},
			theme: {
				color: '#000000',
			},
		};
	
		const rzp = new window.Razorpay(options);
		rzp.open();
	};
	
	
	
   	
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [BillingInfoAreashow, setBillingInfoAreashow] = useState(false);
  
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const HandleShowModal = () => {
    setShow(true);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  const [countryselectedOption, countrysetSelectedOption] = useState('India'); // Move state to parent
  
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const NameRef = useRef(null);
  
  const [EmailSentTo, setEmailSentTo] = useState('');
  const [Email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  const EmailRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const [Phone, setPhone] = useState('');
  const [PhoneError, setPhoneError] = useState(false);
  const [ErrorPhone, setErrorPhone] = useState('');
  const PhoneRef = useRef(null);
  
  const [Company, setCompany] = useState('');
  const [CompanyError, setCompanyError] = useState(false);
  const [ErrorCompany, setErrorCompany] = useState('');
  const CompanyRef = useRef(null);
  
  const [TaxDetail, setTaxDetail] = useState('');
  const [TaxDetailError, setTaxDetailError] = useState(false);
  const [ErrorTaxDetail, setErrorTaxDetail] = useState('');
  const TaxDetailRef = useRef(null);
  
  const [Address, setAddress] = useState('');
  const [AddressError, setAddressError] = useState(false);
  const [ErrorAddress, setErrorAddress] = useState('');
  const AddressRef = useRef(null);
  
  const [City, setCity] = useState('');
  const [CityError, setCityError] = useState(false);
  const [ErrorCity, setErrorCity] = useState('');
  const CityRef = useRef(null);
  
  
  const [State, setState] = useState('');
  const [StateError, setStateError] = useState(false);
  const [ErrorState, setErrorState] = useState('');
  const StateRef = useRef(null);
  
  
  const [PostalCode, setPostalCode] = useState('');
  const [PostalCodeError, setPostalCodeError] = useState(false);
  const [ErrorPostalCode, setErrorPostalCode] = useState('');
  const PostalCodeRef = useRef(null);
  
  
  const [BiilingName, setBiilingName] = useState('');
  const [BillingPhone, setBillingPhone] = useState('');
  const [BillingEmail, setBillingEmail] = useState('');
  const [BillingCompany, setBillingCompany] = useState('');
  const [BillingTax, setBillingTax] = useState('');
  const [BillingAddress, setBillingAddress] = useState('');
  const [BillingCity, setBillingCity] = useState('');
  const [BillingState, setBillingState] = useState('');
  const [BillingCountry, setBillingCountry] = useState('');
  const [BillingPostalCode, setBillingPostalCode] = useState('');
  
  const [ErrorCountry, setErrorCountry] = useState('');
  
  const handleSaveBillingInfo = () =>{
	  
		
		setErrorCompany("");
		setCompanyError(false);
		setErrorAddress("");
		setAddressError(false);
		setErrorCity("");
		setCityError(false);
		setErrorState("");
		setStateError(false);
		setErrorPostalCode("");
		setPostalCodeError(false);
		setErrorCountry("");
	if (Company.trim() === '') {
		setErrorCompany("Please enter company name");
		setCompanyError(true);
		CompanyRef.current.focus();
		return;
	}else if (Address.trim() === '') {
		setErrorAddress("Please enter company's address");
		setAddressError(true);
		AddressRef.current.focus();
		return;
	}else if (City.trim() === '') {
		setErrorCity("Please enter city name");
		setCityError(true);
		CityRef.current.focus();
		return;
	}else if (State.trim() === '') {
		setErrorState("Please enter state name");
		setStateError(true);
		StateRef.current.focus();
		return;
	}else if (PostalCode.trim() === '') {
		setErrorPostalCode("Please enter postal code");
		setPostalCodeError(true);
		PostalCodeRef.current.focus();
		return;
	}else if (countryselectedOption.trim() === '') {
		setErrorCountry("Please select a country");
		return;
	}
	
	handleSaveInfo();

  };
  

  const handleSaveInfo = async () => {
		const requestData = {
			username: CookieAccountId,
			companyName: Company,
			gst: TaxDetail,
			address: Address,
			city: City,
			state: State,
			zip: PostalCode,
			country: countryselectedOption,
		};
		try {
			const response = await fetch(`${ServerApiDomain}/api/business/update`, {
				method: 'PUT',
				headers: {
				'Content-Type': 'application/json', // Sending raw JSON data
				},
				body: JSON.stringify(requestData),
			});

			if (response.ok) {
				
				const newApi = await response.json(); 
				
				setBillingInfoAreashow(true);
				setShow(false);
				setBiilingName(CookieAccountName);
				setBillingEmail(CookieAccountEmail);
				setBillingCompany(Company);
				setBillingTax(TaxDetail);
				setBillingAddress(Address);
				setBillingCity(City);
				setBillingState(State);
				setBillingPostalCode(PostalCode);
				setBillingCountry(countryselectedOption);
				showSnackbar('Billing Info Saved', 'success');
			} else {
				showSnackbar('Failed to save the billing info', 'error');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};
  const [InputApiArea, setInputApiArea] = useState('true');
  const today = new Date();

  // Format today's date as '24 Sep 2024'
  const formattedDate = today.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
  useEffect(() => {
    document.title = 'Checkout | Aadow';

  }, []);

  return (
    <SideBar>
		<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
      <div className="campaign-overview-content">
        <div className="checkout-body">
          <div className="breadcrumb-area">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to="/select-plan">Select Plan</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Checkout</li>
              </ol>
            </nav>
          </div>
		  
		<div className="checkout-area">
		  <div className="row">
			<div className="col-8">
				<div className="left-area">
					<div className="left-header-area">
						<h2 className="main-plan-heading">{PlanName} Plan <Link className="change-plan" to="/select-plan">Change</Link></h2>
						<h6 className="main-plan-subheading">Your plan will start from today - {formattedDate}</h6>
					</div>
					
					<div className="duration-plan-area">
					  {/* Monthly Plan */}
					  <div 
						className={`duration-plan-col ${selectedPlan === 'monthly' ? 'active-duration-plan' : ''}`}
						onClick={() => handlePlanSelect('monthly')}
					  >
						<h2 className="duration-plan-heading">
						  <i className={`bi ${selectedPlan === 'monthly' ? 'bi-check-circle-fill' : 'bi-circle'}`}></i> Monthly
						</h2>
						<div className="duration-plan-bottom-area">
						  <div className="row">
							<div className="col-9">
							  <h4 className="duration-plan-bottom-heading">₹ {PlanPriceMonthly}/mo</h4>
							  <h4 className="duration-plan-bottom-subheading">₹ {PlanPriceMonthly} Billed Monthly</h4>
							</div>
						  </div>
						</div>
					  </div>

					  {/* Half-Yearly Plan */}
					  <div 
						className={`d-none duration-plan-col ${selectedPlan === 'half-yearly' ? 'active-duration-plan' : ''}`}
						onClick={() => handlePlanSelect('half-yearly')}
					  >
						<h2 className="duration-plan-heading">
						  <i className={`bi ${selectedPlan === 'half-yearly' ? 'bi-check-circle-fill' : 'bi-circle'}`}></i> Half-Yearly
						</h2>
						<div className="duration-plan-bottom-area">
						  <div className="row">
							<div className="col-7">
							  <h4 className="duration-plan-bottom-heading">₹ {PlanPriceMonthlyForYear}/mo</h4>
							  <h4 className="duration-plan-bottom-subheading">₹ {TotalYearlyBillSubtotal} Billed Yearly</h4>
							</div>
							<div className="col-5 text-end">
							  <span className="saving-label">10% off</span>
							</div>
						  </div>
						</div>
					  </div>

					  {/* Yearly Plan */}
					  <div 
						className={`duration-plan-col ${selectedPlan === 'yearly' ? 'active-duration-plan' : ''} ${CookieSelectedPlanType === 'EmailTransactional' ? 'd-none' : ''}`}
						onClick={() => handlePlanSelect('yearly')}
					  >
						<h2 className="duration-plan-heading">
						  <i className={`bi ${selectedPlan === 'yearly' ? 'bi-check-circle-fill' : 'bi-circle'}`}></i> Yearly
						</h2>
						<div className="duration-plan-bottom-area">
						  <div className="row">
							<div className="col-7">
							  <h4 className="duration-plan-bottom-heading">₹ {PlanPriceMonthlyForYear}/mo</h4>
							  <h4 className="duration-plan-bottom-subheading">₹ {TotalYearlyBillSubtotal} Billed Yearly</h4>
							</div>
							<div className="col-5 text-end">
							  <span className="saving-label">10% off</span>
							</div>
						  </div>
						</div>
					  </div>
					</div>
					
					
					<div className="features-plan-area">
						<h5 className="features-plan-heading">Features</h5>
						<div className="features-box">
						{CookieSelectedPlanType === 'EmailTransactional' ? (
							<div className="row">

								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> {credits} Email Credits</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> RESTful APIs</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> Dynamic templates</h6>
								</div>
								
								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> Unlimited log retention</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> Email support</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> Upto 10 APIs</h6>
								</div>
								
								

							</div>
						) : (
							<div className="row">

								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> {credits} Email Credits</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> Drag & Drop Email Editor</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list"><i className="bi bi-check-lg"></i> Unlimited Contacts</h6>
								</div>
								
								<div className="col-4">
									<h6 className="feature-list">{PlanName === 'Pro' || PlanName === 'Ultimate' ? (<i className="bi bi-check-lg"></i>) : ( <i className="bi bi-check-lg text-secondary"></i> )} API Email Integration</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list">{PlanName === 'Pro' || PlanName === 'Ultimate' ? (<i className="bi bi-check-lg"></i>) : ( <i className="bi bi-check-lg text-secondary"></i> )} Bulk List Upload</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list">{PlanName === 'Pro' || PlanName === 'Ultimate' ? (<i className="bi bi-check-lg"></i>) : ( <i className="bi bi-check-lg text-secondary"></i> )} No Daily Limits</h6>
								</div>
								
								<div className="col-4">
									<h6 className="feature-list">{PlanName === 'Ultimate' ? (<i className="bi bi-check-lg"></i>) : ( <i className="bi bi-check-lg text-secondary"></i> )} AI Integration</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list">{PlanName === 'Ultimate' ? (<i className="bi bi-check-lg"></i>) : ( <i className="bi bi-check-lg text-secondary"></i> )} AMP Email</h6>
								</div>
								<div className="col-4">
									<h6 className="feature-list">{PlanName === 'Ultimate' ? (<i className="bi bi-check-lg"></i>) : ( <i className="bi bi-check-lg text-secondary"></i> )} 3 Sector Email</h6>
								</div>

							</div>
						)}
						</div>
					</div>	
					{BillingInfoAreashow && (
					<div className="features-plan-area">
						<div className="row align-items-center">
							<div className="col-6">
								<h5 className="features-plan-heading d-block">Billing Information</h5>
							</div>
							<div className="col-6 text-end">
								<a href="#" onClick={HandleShowModal} className="edit-link">Edit</a>
							</div>
						</div>	
						<div className="features-box">
							<div className="row">
								<div className="col-12">
									<h6 className="billing-info-list-active">{BiilingName} | {BillingEmail}</h6>
									<h6 className="billing-info-list">{BillingCompany} {BillingTax && <span>| {BillingTax}</span>}</h6>
									<h6 className="billing-info-list">{BillingAddress}, {BillingCity}, {BillingState}, {BillingCountry} - {BillingPostalCode}</h6>
								</div>
							</div>
						</div>
					</div>	
					)}
					
					
					
				</div>	
			</div>
			
			<div className="col-4">
				<div className="right-area">
					<div className="right-header-area">
						<h2 className="main-plan-heading">Summary</h2>
					</div>

					<div className="right-header-area pt-4">
						<div className='row'>
							<div className='col-6'>
								<h6 className="main-plan-subheading text-dark">{PlanName} Plan</h6>
								<p className="main-plan-subheading fs-6">{credits} emails/month</p>
							</div>
							<div className='col-6 text-end'>
								<h6 className="main-plan-subheading text-dark">₹ {CheckoutSummaryAmount}/month</h6>
								<p className="main-plan-subheading fs-6">₹ {CheckoutSummaryAmount} X {CheckoutSummaryDuration} Month(s)</p>
							</div>
						</div>
					</div>

					<div className="billing-total-area">
						<div className="row"><div className="col-6"><h6 className="billing-summary-left">Subtotal</h6></div><div className="col-6"><h6 className="billing-summary-right">₹ {CheckoutSubtotal}</h6></div>	
						</div>
						<div className="row"><div className="col-6"><h6 className="billing-summary-left">Estimated Tax</h6></div><div className="col-6"><h6 className="billing-summary-right">₹ {CheckoutTax}</h6></div>	
						</div>
					</div>
					
					<div className="final-total-area">
						<div className="row"><div className="col-6"><h6 className="billing-summary-left">Final Total</h6></div><div className="col-6"><h6 className="billing-summary-right">₹ {CheckoutFinalTOtal}</h6></div>	
						</div>
					</div>
					
					<p>By Proceeding, I agree to the <a target='_blank' href="https://portal.aadow.com/terms">Terms & Conditions</a> and <a target='_blank' href="https://portal.aadow.com/refund-policy">Refund Policy</a> of Aadow.</p>

					<button type="button" onClick={handlePayment} className="primary-button w-100">Make Payment</button>
					
				</div>
			</div>
		  </div>
		</div>
		  
		  
        </div>
		
		{show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog modal-lg" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Billing Information</h5>
						  </div>
						  <div className="col-1 text-end d-none">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 px-4">
					<div className="row">
						
						<div className="col-6">
							<label>Company Name<b className="text-danger">*</b></label>
							<input type="text" ref={CompanyRef} className={`input-forms w-100 ${CompanyError ? 'input-error' : ''}`} value={Company} onChange={(e) => {
								  setCompany(e.target.value);
								}} />
							{ErrorCompany && <div className="error-message">{ErrorCompany}</div>}
						</div>
						<div className="col-6">
							<label>GST Detail</label>
							<input type="text" ref={TaxDetailRef} className={`input-forms w-100 ${TaxDetailError ? 'input-error' : ''}`} value={TaxDetail} onChange={(e) => {
								  setTaxDetail(e.target.value);
								}} />
							{ErrorTaxDetail && <div className="error-message">{ErrorTaxDetail}</div>}
						</div>
						
						<div className="col-12">
							<label>Address<b className="text-danger">*</b></label>
							<input type="text" ref={AddressRef} className={`input-forms w-100 ${AddressError ? 'input-error' : ''}`} value={Address} onChange={(e) => {
								  setAddress(e.target.value);
								}} />
							{ErrorAddress && <div className="error-message">{ErrorAddress}</div>}
						</div>
						
						<div className="col-6">
							<label>City<b className="text-danger">*</b></label>
							<input type="text" ref={CityRef} className={`input-forms w-100 ${CityError ? 'input-error' : ''}`} value={City} onChange={(e) => {
								  setCity(e.target.value);
								}} />
							{ErrorCity && <div className="error-message">{ErrorCity}</div>}
						</div>
						
						<div className="col-6">
							<label>State<b className="text-danger">*</b></label>
							<input type="text" ref={StateRef} className={`input-forms w-100 ${StateError ? 'input-error' : ''}`} value={State} onChange={(e) => {
								  setState(e.target.value);
								}} />
							{ErrorState && <div className="error-message">{ErrorState}</div>}
						</div>
						
						
						<div className="col-6">
							<label>Postal Code<b className="text-danger">*</b></label>
							<input type="text" ref={PostalCodeRef} className={`input-forms w-100 ${PostalCodeError ? 'input-error' : ''}`} value={PostalCode} onChange={(e) => {
								  setPostalCode(e.target.value);
								}} />
							{ErrorPostalCode && <div className="error-message">{ErrorPostalCode}</div>}
						</div>
						
						<div className="col-6">
							<label>Country<b className="text-danger">*</b></label>
							<CountrySelect
								countryselectedOption={countryselectedOption}
								countryhandleChange={(value) => {
								  countrysetSelectedOption(value);
								}}
							  />
							{ErrorCountry && <div className="error-message">{ErrorCountry}</div>}
						</div>
				
					</div>	
						
					</div>
					
					<div className="modal-footer ">
						<button type="button" className="secondary-button d-none" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button" onClick={handleSaveBillingInfo}>Save Info</button>
					</div>
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		
      </div>
			
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
	  <br/><br/><br/><br/><br/><br/>
    </SideBar>
  );
};

export default Checkout;