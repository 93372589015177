import React, { useState, useEffect } from 'react';
import '../../css/public/pricing.css';
import EmailPlans from './components/pricing/EmailPlans';
import Cookies from 'js-cookie';

const Pricing = () => {
  const [activePlan, setActivePlan] = useState('email');

  useEffect(() => {
    document.title = 'Select Plan | Aadow';
    const CookietId = Cookies.get('current_Id');
	  const CookieAccountId = Cookies.get('current_accountId');
	  const CookieAccountName = Cookies.get('current_accountName');
	  const CookieAccountEmail = Cookies.get('current_accountEmail');
	  if (CookieAccountId) {
		  
	  } else {
		  window.location.href = `/login`;
	  }  
  }, []);

  return (
  <div>
    <div className="pricing-body">
	  
      <div className="header-area">
        <h1 className="main-heading">Select a Plan</h1>
        <p className="main-subheading">
          Tailored Solutions for Every Budget. Find the Ideal Pricing Option that will Propel your Business to New Heights
        </p>
      </div>


      <div className="plans-content">
        <EmailPlans />
      </div>
    </div>
    </div>
  );
};

export default Pricing;
