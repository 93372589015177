import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import '../../css/private/email-templates.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Cookies from 'js-cookie';
import axios from "axios";



const EmailTemplates = () => {

  const [searchParams] = useSearchParams();

	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  // Get the 'campaign_id' parameter from the URL
  const campaignId = searchParams.get('campaign_id');
  const template = searchParams.get('template');
  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');

  
  const API_URL = `${ServerApiDomain}/api/emailtemplates/username/${CookieAccountId}`;
  const AUTH = 'Basic ' + btoa('testuser:testpassword');
 
  useEffect(() => {
    document.title = 'Email Templates | Aadow';
  }, []);
  
  const handleSelect = (item) => {
    const randomTemplateId = Math.floor(10000000 + Math.random() * 90000000).toString();
    const currentDateTime = new Date().toISOString();
  
      const payload = {
        username: CookieAccountId,
        templateId: randomTemplateId,
        templateName: "Untitled Template",
        subject: "",
        previewText: "",
        content: item.content,
        thumbnail: item.thumbnail,
        status: "active",
        type: item.type,
        score: "",
        datetime: currentDateTime,
        timestamp: currentDateTime,
      };
  
      axios
        .post(`${ServerApiDomain}/api/emailtemplates/add`, payload)
        .then((response) => {
          console.log("Template added successfully:", response.data);
          console.log("Template added successfully!");
      window.location.href = `/create/email-template/drag-drop/${randomTemplateId}`;
        })
        .catch((error) => {
          console.error("Error adding template:", error);
          console.log("Failed to add the template. Please try again.");
        });
    };
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [verifyDomainLink, setVerifyDomainLink] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
	const current_username = 'pachorieshan';
	const username = 'testuser';
	const password = 'testpassword';
	const encodedCredentials = btoa(`${username}:${password}`);
	
	const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
	
	const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
        setSelectedId(null);
    };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorDomain("");
	setDomain("");
    setDomainError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Domain, setDomain] = useState('');
  const [DomainError, setDomainError] = useState(false);
  const [ErrorDomain, setErrorDomain] = useState('');
  const [DomainAdded, setDomainAdded] = useState('');
  const [DomainSentTo, setDomainSentTo] = useState('');
  const DomainRef = useRef(null);
  
  
  
  const [InputApiArea, setInputApiArea] = useState('true');
  
  
  const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [noData, setNoData] = useState(false);
    const loadedIds = useRef(new Set());

    const fetchData = useCallback(async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        try {
            const response = await fetch(`${API_URL}?page=${page}`, {
                headers: {
                    'Authorization': AUTH
                }
            });
            const result = await response.json();

            if (result.content.length > 0) {
                const newData = result.content.filter(item => !loadedIds.current.has(item.id));
                
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    newData.forEach(item => loadedIds.current.add(item.id)); // Mark IDs as loaded
                    setPage(prevPage => prevPage + 1);
                }
            } else {
                setHasMore(false); // No more data available
            }

            // Check if no data was returned initially
            if (page === 0 && result.content.length === 0) {
                setNoData(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [loading, hasMore, page]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchData();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, fetchData]);
	
	
	
	const handleDelete = async () => {
		try {
			const response = await fetch(`${ServerApiDomain}/api/emailtemplates/${selectedId}/${CookieAccountId}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Basic ${encodedCredentials}`,
				},
			});

			if (response.ok) {
            setData(prevData => {
                const updatedData = prevData.filter(item => item.id !== selectedId);
                setNoData(updatedData.length === 0); // Check if the data is empty
                return updatedData;
            });
            showSnackbar('Email Template Removed!', 'success');
        } else {
            alert('Failed to delete the Email Template');
        }
		} catch (error) {
			alert('Error:', error);
		} finally {
			handleCloseDeleteModal();
		}
	};
  

  return (
    <SideBar>
      <div className="page-content ">
        <div className="email-templates-body ">
        <div className="breadcrumb-area"> 
			 <nav aria-label="breadcrumb">
			  <ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
				<li className="breadcrumb-item active" aria-current="page">Email Templates</li>
			  </ol>
			</nav>
		</div>	
		 <div className="header-area">
     {campaignId ? (
            <div></div>
     ):(
      <div className="top-area">
        <div className="row align-items-center">
          <div className="col-7">
            <h1 className="main-heading">Email Templates</h1>
            <h6 className="main-subheading">Create, Customize, and Manage Your Email Templates with Ease!</h6>
          </div>
          <div className="col-5">
            <Link to="/email-templates/template-library" style={{fontSize: '0.8vw'}} className="primary-button text-decoration-none float-end mx-2">Create new Template</Link>
          </div>
        </div>
      </div>
     )}
          </div>
		  
		  <div className="my-template-area">
			<h3 className="template-label">My Templates</h3>
			<div className="row">
			
				
						
								
								{noData ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
											<h2 className="empty-error-heading">No Email Templates Found</h2>
											<h6 className="empty-error-subheading" >We couldn't find any email templates associated with your account.</h6>
											<Link to="/email-templates/template-library" className="primary-button text-decoration-none mt-2 d-inline-block mx-2">Create new Template</Link>
										</div>
									</center>
								) : (
									<>
										<div className="row">
											{data.map((item, index) => {
											const formattedDate = new Intl.DateTimeFormat('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}).format(new Date(item.datetime));

											return (
												<div key={item.id} ref={data.length === index + 1 ? lastElementRef : null} className="col-3">
													<div className="template-col shadow-sm">
														<div className="image-area">
															<img className="template-image" src={`${item.thumbnail}`} />
															<div className="overlay">
                              {campaignId ? (
                                <div>
                                   <Link
                                      to={`/create/email-template/${item.type}/${item.templateId}?campaign_id=${campaignId}&template=${template}`}
                                      className="text-decoration-none center-button edit-button"
                                    >
                                      Select
                                    </Link>
                                </div>
                              ) : (  
                                <div>
																<Link to={`/create/email-template/${item.type}/${item.templateId}`} className="text-decoration-none center-button edit-button"><i className="bi bi-pencil"></i></Link>
																<button onClick={() => handleSelect(item)} className="center-button bg-dark delete-button"><i className="bi bi-copy"></i></button>
																<button onClick={() => handleClickOpen(item.id)} className="center-button delete-button"><i className="bi bi-trash"></i></button>
                                </div>
                                )}
															</div>
														</div>
														<div className="bottom-area">
														<h4 className="email-template-name"><Link to="">{item.templateName}</Link></h4>
														<h6 className="email-template-description">Last Edited on {formattedDate}</h6>
														</div>
													</div>
												</div>
												
												);
											})}
											
										</div>
										{loading && <p>Loading more data...</p>}
									</>
								)}
								
								
								
						
				
				
				
			</div>
		  </div>
		  
		  
        </div>
      </div>
			<Dialog open={open} onClose={handleCloseDeleteModal}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this Template? This action cannot be undone.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDeleteModal} className="text-secondary">
							Cancel
						</Button>
						<Button onClick={handleDelete} className="text-danger">
							Delete
						</Button>
					</DialogActions>
			</Dialog>
			
			<Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
			</Snackbar>
			
    </SideBar>
  );
};

export default EmailTemplates;
