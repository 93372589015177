import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/audience-overview.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Cookies from 'js-cookie';
import Backdrop from "@mui/material/Backdrop";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

import CircularProgress from "@mui/material/CircularProgress";


const AudienceOverview = () => {
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
	const [FullPageloading, setFullPageLoading] = useState(false);
  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');

  const [ApiInput, setApiInput] = useState('');
  
  const [apis, setApis] = useState([])

  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRefs = useRef([]);

  const toggleDropdown = (index) => {
    setShowDropdown(showDropdown === index ? null : index);
  };

  
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const closeDropdown = () => {
    setShowDropdown(null);
  };

  const handleClickOpen = (id) => {
	setSelectedId(id);
	setOpen(true);
};

const handleCloseDeleteModal = () => {
	setOpen(false);
	setSelectedId(null);
};


const [openDeleteFolderModal, setopenDeleteFolderModal] = useState(false);
const [openDeleteListModal, setopenDeleteListModal] = useState(false);

const handleCloseDeleteFolderModal = () => {
	setopenDeleteFolderModal(false);
};

const handleCloseDeleteListModal = () => {
	setopenDeleteListModal(false);
};

const handleOpenDeleteFolderModal = () => {
	setopenDeleteFolderModal(true);
};

const handleOpenDeleteListModal = () => {
	setopenDeleteListModal(true);
};



const handleDelete = async () => {
	try {
		const response = await fetch(`${ServerApiDomain}/api/emailcontacts/delete?id=${selectedId}&username=${CookieAccountId}`, {
			method: 'DELETE',
			
		});

		if (response.ok) {
			setContacts(prevContacts => prevContacts.filter(contact => contact.id !== selectedId));
            showSnackbar('Contact Removed!', 'success');
		} else {
			alert('Failed to delete the Contact');
		}
	} catch (error) {
		alert('Error:', error);
	} finally {
		handleCloseDeleteModal();
	}
};
  
  const [SenderEmailselectedValue, setSenderEmailselectedValue] = useState("");
  const [SegmentselectedValue, setSegmentselectedValue] = useState("");
  const [ListselectedValue, setListselectedValue] = useState("");
  const CurrentPlanName = Cookies.get('CurrentPlanName');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [ExportModalshow, setExportModalshow] = useState(false);
  const handleExportModalClose = () => setExportModalshow(false);
  const handleExportModalShow = () => setExportModalshow(true);
  
  const handleSegmentChange = (event) => {
	  setSegmentselectedValue(event.target.value); // Update state with the selected value
	};

	const handleListChange = (event) => {
	  setListselectedValue(event.target.value); // Update state with the selected value
	};
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const handleExportModalShowHide = () => {
	  setInputApiArea('true');
	  setExportModalshow(true);
  };
  
  const [InputApiArea, setInputApiArea] = useState('true');
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const [EmailSentTo, setEmailSentTo] = useState('');
  const NameRef = useRef(null);
  
  const [Email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  
  const [ErrorListName, setErrorListName] = useState('');
  
  const EmailRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };
  
  const [SearchContact, setSearchContact] = useState('');
  
  
  const handleSearchContactChange = (value) => {
  };	
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

	

  // Close dropdown when clicking outside of it
  useEffect(() => {
	  
    document.title = 'Audience Overview | Aadow';
    const handleClickOutside = (event) => {
      if (dropdownRefs.current.some(ref => ref && !ref.contains(event.target))) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [listId, setListId] = useState('');
  const [status, setStatus] = useState('');
  const [folderId, setFolderId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const pageSize = 8; // Number of items per page


  
  const [Folder, setFolder] = useState('');
  const [List, setList] = useState('');
  const [ContactStatus, setContactStatus] = useState('');
  const [segment, setSegment] = useState('');
  
  
  const [DownloadselectedFolderOption, setDownloadselectedFolderOption] = useState('');
  const [DownloadselectedListOption, setDownloadselectedListOption] = useState('');
  const [Downloadstatus, setDownloadstatus] = useState('');
  const [Downloadsegment, setDownloadsegment] = useState('');
  

 
  
  const updateQueryParams = (params) => {
	const searchParams = new URLSearchParams(window.location.search);
  
	Object.keys(params).forEach((key) => {
	  if (params[key]) {
		searchParams.set(key, params[key]); // Add or update parameter
	  } else {
		searchParams.delete(key); // Remove parameter if value is falsy
	  }
	});
  
	window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
  };
  
  const handleFolderSelectChange = (event) => {
	const newFolderId = event.target.value;
	const selectedFolder = folders.find(folder => folder.folderid === newFolderId);
	const selectedFolderName = selectedFolder ? selectedFolder.folder : null;
  
	setCurrentPage(0);
	setSelectedFolderOption(newFolderId);
	setGetFolderId(newFolderId);
	setGetFolderName(selectedFolderName);
	setSelectedListOption(""); // Reset list filter when folder changes
  
	updateQueryParams({ folderid: newFolderId, listid: '', page: 0 });
	fetchContacts(0);
  };
  
  const handleListSelectChange = (event) => {
	const newListId = event.target.value;
	const selectedList = lists.find(list => list.listid === newListId);
	const selectedListName = selectedList ? selectedList.listname : null;
  
	setCurrentPage(0);
	setSelectedListOption(newListId);
	setGetListId(newListId);
	setGetListName(selectedListName);
  
	updateQueryParams({ listid: newListId, page: 0 });
	fetchContacts(0);
  };
  
  const handleContactStatusbyChange = (event) => {
	const newStatus = event.target.value;
	setCurrentPage(0);
	setStatus(newStatus);
  
	updateQueryParams({ status: newStatus, page: 0 });
	fetchContacts(0);
  };
  
  const handleSegmentbyChange = (event) => {
	const newSegment = event.target.value;
	setCurrentPage(0);
	setSegment(newSegment);
  
	updateQueryParams({ segment: newSegment, page: 0 });
	fetchContacts(0);
  };
  
  

  const fetchContacts = async (page = 0) => {
	try {
	  setFullPageLoading(true);
  
	  const searchParams = new URLSearchParams(window.location.search);
	  const listId = searchParams.get('listid') || '';
	  const status = searchParams.get('status') || '';
	  const segment = searchParams.get('segment') || '';
	  const folderId = searchParams.get('folderid') || '';
	  const search = searchParams.get('search') || '';
  
	  const params = new URLSearchParams({ username: CookieAccountId, page });
	  if (search) params.append('search', search);
	  if (listId) params.append('listid', listId);
	  if (status) params.append('status', status);
	  if (segment) params.append('segment', segment);
	  if (folderId) params.append('folderid', folderId);
  
	  const url = `${ServerApiDomain}/api/emailcontacts/filters?${params.toString()}`;
  
	  const response = await axios.get(url, {
		auth: { username: 'testuser', password: 'testpassword' },
	  });
  
	  setContacts(response.data.content);
	  setTotalPages(response.data.totalPages);
	  setError(null);
	} catch (err) {
	  setError('Failed to fetch contacts. Please try again later.');
	  setContacts([]);
	  setTotalPages(0);
	} finally {
	  setFullPageLoading(false);
	}
  };
  
  
  const handleDownloadContactStatusbyChange = (event) => {
	const newStatus = event.target.value;
	setDownloadstatus(newStatus);
  };
  
  const handleDownloadSegmentbyChange = (event) => {
	const newSegment = event.target.value;
	setDownloadsegment(newSegment);
  };

  useEffect(() => {
	setFullPageLoading(true); // Start loading before fetching
	fetchContacts(currentPage);
  }, [currentPage, listId, status, segment, folderId, searchTerm]);

  const handleNextPage = () => {
	if (currentPage < totalPages - 1) {
	  setFullPageLoading(true); // Start loading state
	  setCurrentPage((prevPage) => prevPage + 1);
	}
  };
  

  const handlePrevPage = () => {
	if (currentPage > 0) {
	  setFullPageLoading(true); // Start loading state
	  setCurrentPage((prevPage) => prevPage - 1);
	}
  };
  

  const handlePageClick = (page) => {
	setFullPageLoading(true); // Start loading state
	setCurrentPage(page);
  };
  

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      // If Enter is pressed, we trigger the fetch
      setCurrentPage(0); // Reset to first page when searching
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    
  };

  const handleContactSearch = () => {
	if (searchTerm.trim() === '') {
	  console.log('Search term is empty, skipping search...');
	  return;
	}
	
	setCurrentPage(0); // Reset to the first page when searching
	fetchContacts(0); // Trigger the fetch with the current search term
  };
  

  const [selectedListOption, setSelectedListOption] = useState("");
  
  const [selectedFolderOption, setSelectedFolderOption] = useState("");

  
  const [GetListId, setGetListId] = useState('');
  const [GetListName, setGetListName] = useState('');
  const [GetFolderId, setGetFolderId] = useState('');
  const [GetFolderName, setGetFolderName] = useState('');
  const [folders, setFolders] = useState([]); // To store the folder list
  const [lists, setLists] = useState([]); // To store the lists based on the selected folder

  useEffect(() => {
    axios
      .get(`${ServerApiDomain}/api/email-contacts/distinct-lists?username=${CookieAccountId}`)
      .then((response) => {
        setFolders(response.data); // Save the folder data in state
      })
      .catch((error) => {
        console.error('Error fetching folders:', error);
      });
  }, []);
  
  // Handle folder selection
  

  const handleDownloadFolderSelectChange = (event) => {
    const selectedFolderId = event.target.value; // Get the folderid
    
    // Find the folder with the selected folderid
    const selectedFolder = folders.find(folder => folder.folderid === selectedFolderId);
    
    // Get the foldername of the selected folder
    const selectedFolderName = selectedFolder ? selectedFolder.folder : null;
  
    setDownloadselectedFolderOption(selectedFolderId); // Set the selected folderid
    
    // Show an alert with both the folderid and the foldername
    console.log(`Selected Folder ID: ${selectedFolderId}, Folder Name: ${selectedFolderName}`);
  
    // Filter the lists based on the selected folderid
    const filteredLists = folders.filter(
      (folder) => folder.folderid === selectedFolderId // Match by folderid
    );
    setLists(filteredLists); // Update the list options based on selected folder
    setGetFolderId(selectedFolderId);
    setGetFolderName(selectedFolderName);
    setSelectedListOption(''); // Reset the list selection when folder changes



  };
  
  const handleDownloadListSelectChange = (event) => {
    const selectedListId = event.target.value; // Get the listid
    
    // Find the list with the selected listid
    const selectedList = lists.find(list => list.listid === selectedListId);
    
    // Get the listname of the selected list
    const selectedListName = selectedList ? selectedList.listname : null;
  
    setDownloadselectedListOption(selectedListId); // Set the selected listid
    
    // Show an alert with both the listid and the listname
    console.log(`Selected List ID: ${selectedListId}, List Name: ${selectedListName}`);
    
    setGetListId(selectedListId);
    setGetListName(selectedListName);
  };


  // Handle list selection

  const handleExportList = async () => {
	const apiUrl = `https://app.aadow.com/DownloadContacts.php?username=${CookieAccountId}&status=${Downloadstatus}&segment=${Downloadsegment}&folderid=${DownloadselectedFolderOption}&listid=${DownloadselectedListOption}`;
	window.open(apiUrl, '_blank');
	

  };


  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  const deleteByFolderId = async () => {
    try {
      setLoading(true); // Show loading state
      setError(null); // Reset error

      const response = await axios.delete(
        `${ServerApiDomain}/api/emailcontacts/deleteByFolderId?username=${CookieAccountId}&folderid=${folderId}`
      );

      // On success, show a message (you can use alert or other methods)
      console.log(response.data); // "Contacts deleted successfully by folderid!"

      // Reload the page after 3 seconds
	  showSnackbar('Folder Removed, Reloading...', 'success');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (err) {
      setError("Error deleting the folder!");
      console.error(err);
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  // Function to delete contacts by listId
  const deleteByListId = async () => {
    try {
      setLoadingList(true); // Show loading state
      setError(null); // Reset error

      const response = await axios.delete(
        `${ServerApiDomain}/api/emailcontacts/deleteByListId?username=${CookieAccountId}&listid=${listId}`
      );

      // On success, show a message
      console.log(response.data); // "Contacts deleted successfully by listid!"

      // Reload the page after 3 seconds
	  showSnackbar('List Removed, Reloading...', 'success');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (err) {
      setError("Error deleting the list!");
      console.error(err);
    } finally {
		setLoadingList(false); // Hide loading state
    }
  };
  

  return (
    <SideBar>
      <div className="page-content ">
	  	<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
        <div className="audience-overview-body ">
        <div className="breadcrumb-area"> 
			 <nav aria-label="breadcrumb">
			  <ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
				<li className="breadcrumb-item active" aria-current="page">Audience Overview</li>
			  </ol>
			</nav>
		</div>	
		 <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-7">
                  <h1 className="main-heading">Audience Overview</h1>
                  <h6 className="main-subheading">Manage Your Contacts and Subscribers Efficiently</h6>
                </div>
                <div className="col-5">
                  <button type="button" onClick={handleShow} style={{fontSize: '0.8vw'}} className="primary-button float-end mx-2 me-0">Import Contacts</button>
                  <button type="button" className="primary-outline-button float-end mx-2 d-none">Add a Contact</button>
                </div>
              </div>
            </div>
          </div>
		  
		  <div className="audience-metric-area d-none">
			<div className="row">
				<div className="col">
					<div className="audience-metric-col">
						<div className="row align-items-center">
							<div className="col-2">
								<i className="bi bi-people icon"></i>
							</div>
							<div className="col-10">
								<h3 className="secondary-heading">All Contacts</h3>
							</div>
						</div>
						<div className="row align-items-center mt-3">
							<div className="col-7">
								<h2 className="main-heading">30,492</h2>
							</div>
							<div className="col-5 text-end">
								<span className="metric-today-change-up"><i className="bi bi-graph-up-arrow me-1"></i> 7.4%</span>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col">
					<div className="audience-metric-col">
						<div className="row align-items-center">
							<div className="col-2">
								<i className="bi bi-person-check icon"></i>
							</div>
							<div className="col-10">
								<h3 className="secondary-heading">Active</h3>
							</div>
						</div>
						<div className="row align-items-center mt-3">
							<div className="col-7">
								<h2 className="main-heading">28,313</h2>
							</div>
							<div className="col-5 text-end">
								<span className="metric-today-change-up"><i className="bi bi-graph-up-arrow me-1"></i> 23.59%</span>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col">
					<div className="audience-metric-col">
						<div className="row align-items-center">
							<div className="col-2">
								<i className="bi bi-person-x icon"></i>
							</div>
							<div className="col-10">
								<h3 className="secondary-heading">Non-Active</h3>
							</div>
						</div>
						<div className="row align-items-center mt-3">
							<div className="col-7">
								<h2 className="main-heading">2,094</h2>
							</div>
							<div className="col-5 text-end">
								<span className="metric-today-change-down"><i className="bi bi-graph-down-arrow me-1"></i> -1.56%</span>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		  </div>

		  <div className="filter-area">
            <div className="row">
              <div className="col">
                <label>Folder</label>
                <div  className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-folder"></i>
				  <Select
                          className="input-form"
                          sx={{ fontSize: '3vw' }}
                          disableUnderline
                          fullWidth
						  style={{marginTop: '0', height: '1.5vw', border: 'none'}}
                          value={selectedFolderOption}
                          onChange={handleFolderSelectChange}
                          input={<InputBase />}
                        >
                          <MenuItem value="" selected>All</MenuItem>
                          {/* Dynamically populate folder options with folderid as value */}
                          {[
                            ...new Map(folders.map((folder) => [folder.folderid, folder])).values() // Ensure unique folderid
                          ].map((folder) => (
                            <MenuItem key={folder.folderid} value={folder.folderid}>
                              {folder.folder} {/* Display folder name, but value is folderid */}
                            </MenuItem>
                          ))}
                        </Select>

                </div>
              </div>
              
              <div className="col">
                <label>List</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-card-list"></i>
                  <Select
                          className="input-form"
                          sx={{ fontSize: '4vw' }}
                          disableUnderline
                          fullWidth
						  style={{marginTop: '0', height: '1.5vw', border: 'none'}}
                          value={selectedListOption}
                          onChange={handleListSelectChange}
                          input={<InputBase />}
                        >
                          <MenuItem value="" selected>All</MenuItem>
                          {/* Dynamically populate list options with listid as value */}
                          {[
                            ...new Map(lists.map((list) => [list.listid, list])).values() // Ensure unique listid
                          ].map((list) => (
                            <MenuItem key={list.listid} value={list.listid}>
                              {list.listname} {/* Display list name, but value is listid */}
                            </MenuItem>
                          ))}
                        </Select>
                </div>
              </div>
              <div className="col">
                <label>Status</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-toggle-off"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						style={{marginTop: '0', height: '1.5vw', border: 'none'}}
						value={status}
						onChange={handleContactStatusbyChange}
						input={<InputBase />}
					>
						<MenuItem value="">All</MenuItem>
						<MenuItem value="active">Active</MenuItem>
						<MenuItem value="inactive">InActive</MenuItem>
					</Select>
                </div>
              </div>
              {(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') && (
              <div className="col">
                <label>Segment</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-segmented-nav"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						value={segment}
						style={{marginTop: '0', height: '1.5vw', border: 'none'}}
						onChange={handleSegmentbyChange}
						input={<InputBase />}
					>
						<MenuItem value="">All</MenuItem>
						<MenuItem value="green">Green</MenuItem>
						<MenuItem value="red">Red</MenuItem>
						<MenuItem value="orange">Orange</MenuItem>
					</Select>
                </div>
              </div>
			  )}
          </div>
          </div>

		  {/* Overlay */}
		  <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggleSideNav}></div>

		  {/* Side Navigation */}
		  <div id="mySidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
		  </div>
		  
		  <div className="audience-filter-area">
			  <div className="row align-items-center">
				<div className="col-5">
				<input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)} // Only update the search term
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
						handleContactSearch(); // Trigger the search only on Enter press
						}
					}}
					autoComplete="off"
					className="contact-search"
					placeholder="Search Contacts"
					/>
				</div>
				{contacts.length !== 0 && !error ? (
				<>
				<div className="col-7 text-end">
				{folderId && (
					<button
					type="button"
					style={{fontSize: '0.8vw'}}
					className="secondary-button bg-danger text-white border-danger me-3"
					onClick={handleOpenDeleteFolderModal}
					disabled={loading}
					>
					{loading ? (
						<span>Deleting...</span>
					) : (
						<>
						<i className="bi bi-trash me-2"></i> Delete Folder
						</>
					)}
					</button>
				)}

				{listId && (
					<button
					type="button"
					style={{fontSize: '0.8vw'}}
					className="secondary-button bg-danger text-white border-danger me-3"
					onClick={handleOpenDeleteListModal}
					disabled={loadingList}
					>
					{loadingList ? (
						<span>Deleting...</span>
					) : (
						<>
						<i className="bi bi-trash me-2"></i> Delete List
						</>
					)}
					</button>
				)}

					<button type="button" style={{fontSize: '0.8vw'}} onClick={handleExportModalShowHide} className="primary-button	"><i className="bi bi-download me-2"></i> Export Contacts</button>
				</div>
				</>
				):(
				<div></div>
				)}
			  </div>
		  </div>
		  
		  
		  <div className="table-contact-area">
			{contacts.length === 0 && !error ? (
											<center>
												<div className="empty-error-area">
													<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
													<h2 className="empty-error-heading">No contacts found.</h2>
													<h6 className="empty-error-subheading d-none" >We couldn't find any domain authentication records associated with your account.</h6>
												</div>
											</center>
			) : (
			<div>
			<table>
			<thead>
				<tr>
				<th className="th-one">Subscriber</th>
				<th className="th-two">Status</th>
				<th className="th-three">Listname</th>
				{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') && (
				<th className="th-five">Segment</th>
				)}
				<th className="th-six"></th>
				</tr>
			</thead>
			<tbody>
				{contacts.map(contact => (
				<tr key={contact.id}>
					<td className="td-one">
					<div className="row align-items-center">
						<div className="col-2">
						<img src="https://www.aadow.com/icons/user-avatar.png" alt="Person" className="person-image" />
						</div>
						<div className="col-10">
						<h6 className="person-name">{contact.email}</h6>
						<h6 className="person-contact-info">{contact.firstname} {contact.lastname}</h6>
						</div>
					</div>
					</td>
					<td className="td-two"><span className={`person-status ${contact.status}-status text-capitalize`}>{contact.status}</span></td>
					<td className="td-three listname">{contact.listname}</td>
					{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') && (
					<td className="td-five">
					<div className={`segment-container ${contact.segment}`}>
						<div className="first-segment"></div>
						<div className="second-segment"></div>
						<div className="third-segment"></div>
					</div>
					</td>
					)}
					<td className="td-six">
					<div className="dropdown-container" >
						<button className="dropdown-toggle" onClick={() => toggleDropdown(contact.id)} type="button" >
						<i className="bi bi-three-dots-vertical"></i>
						</button>
						{showDropdown === contact.id && (
						<ul className="dropdown-menu show">
							<li><a href="#" className="d-none">Edit</a></li>
							<li><a href="#" className="d-none" >Duplicate</a></li>
							<li><a href="#" className="d-none" >View Report</a></li>
							<div className="line-break d-none"></div>
							<li><a onClick={() => handleClickOpen(contact.id)}  className="text-danger cursor-pointer">Delete</a></li>
						</ul>
						)} 
					</div>
					</td>
				</tr>
				))}
			</tbody>
			</table>
			
			<div className="pagination-area ">
				<div className="row">
					<div className="col-2">
						<button onClick={handlePrevPage} disabled={currentPage === 0} type="button"><i className="bi bi-arrow-left me-2" ></i> Previous</button>
						
					</div>
					<div className="col-8 text-center pagination-numbers">
					{totalPages > 8 ? (
						<>
						{/* First 4 pages */}
						{Array.from({ length: 4 }, (_, index) => (
							<button
							key={index}
							onClick={() => handlePageClick(index)}
							disabled={index === currentPage}
							type="button"
							className={`pagination-btn ${index === currentPage ? 'active' : ''}`}
							>
							{index + 1}
							</button>
						))}
						
							<button type="button" className="pagination-btn border-1">...</button>

						{/* Last 4 pages */}
						{Array.from({ length: 4 }, (_, index) => (
							<button
							key={totalPages - 4 + index}
							onClick={() => handlePageClick(totalPages - 4 + index)}
							disabled={totalPages - 4 + index === currentPage}
							type="button"
							className={`pagination-btn ${totalPages - 4 + index === currentPage ? 'active' : ''}`}
							>
							{totalPages - 4 + index + 1}
							</button>
						))}
						</>
					) : (
						// If there are fewer than 8 pages, display all of them
						Array.from({ length: totalPages }, (_, index) => (
						<button
							key={index}
							onClick={() => handlePageClick(index)}
							disabled={index === currentPage}
							type="button"
							className={`pagination-btn ${index === currentPage ? 'active' : ''}`}
						>
							{index + 1}
						</button>
						))
					)}
					</div>

					<div className="col-2 text-end"> 
						<button type="button" onClick={handleNextPage} disabled={currentPage === totalPages - 1}> Next <i className="bi bi-arrow-right ms-2"></i></button>
					</div>
				</div>
			</div>
			</div>
			)}
			
		  </div>
		  
          
        </div>
      </div>
	  
	   {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100 align-items-center">
						  <div className="col-11">
							<h5 className="modal-title">Import Contacts</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body py-4 px-4">
						
						<Link className="text-decoration-none" to="/import-contacts/csv-upload">
						<div style={{border: '0.1vw solid rgba(220, 220, 220)',cursor: 'pointer', margin: '0vw 0 0vw 0vw', borderRadius: '0.6vw', padding: '1vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '4.5vw', height: '4.5vw',borderRadius: '0.6vw', background: 'rgba(230, 230, 230)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/upload-emails.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '2vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1vw', fontWeight: '600'}} className="create-campaign-type-heading text-dark">Upload a file</h4>
									<h6 style={{fontSize: '0.8vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Select a .csv from your computer</h6>
									</div>
								</div>

							</div>
						</div>
						</Link>
						<Link className="text-decoration-none" to="/import-contacts/copy-paste">
						<div style={{border: '0.1vw solid rgba(220, 220, 220)',cursor: 'pointer', margin: '1vw 0 0vw 0vw', borderRadius: '0.6vw', padding: '1vw 0.8vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '4.5vw', height: '4.5vw',borderRadius: '0.6vw', background: 'rgba(230, 230, 230)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/copy-paste.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '2vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1vw', fontWeight: '600'}} className="create-campaign-type-heading text-dark">Copy & Paste</h4>
									<h6 style={{fontSize: '0.8vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Copy and Paste contacts from your computer</h6>
									</div>
								</div>

							</div>
						</div>
						</Link>
					</div>
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  
		  {ExportModalshow && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100 mb-0">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Export Contact</h5>
						  </div>
						  	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 mt-0 px-4">
					{InputApiArea === 'true' && (
					<div className="row">
						<div className="col-12">
											<div className="row">
												<div className="col-12">
													<label>Folder</label>
													<div className="select-input-form">
													{/* Bootstrap Icon */}
													<i className="bi bi-folder"></i>
													<Select
															className="input-form"
															style={{marginTop: '0', height: '1.5vw', border: 'none'}}
															disableUnderline
															fullWidth
															value={DownloadselectedFolderOption}
															onChange={handleDownloadFolderSelectChange}
															input={<InputBase />}
															>
															<MenuItem value="" selected>All</MenuItem>
															{/* Dynamically populate folder options with folderid as value */}
															{[
																...new Map(folders.map((folder) => [folder.folderid, folder])).values() // Ensure unique folderid
															].map((folder) => (
																<MenuItem key={folder.folderid} value={folder.folderid}>
																{folder.folder} {/* Display folder name, but value is folderid */}
																</MenuItem>
															))}
															</Select>

													</div>
												</div>
												
												<div className="col-12">
													<label>List</label>
													<div className="select-input-form">
													{/* Bootstrap Icon */}
													<i className="bi bi-card-list"></i>
													<Select
															className="input-form"
															disableUnderline
															fullWidth
															style={{marginTop: '0', height: '1.5vw', border: 'none'}}
															value={DownloadselectedListOption}
															onChange={handleDownloadListSelectChange}
															input={<InputBase />}
															>
															<MenuItem value="" selected>All</MenuItem>
															{/* Dynamically populate list options with listid as value */}
															{[
																...new Map(lists.map((list) => [list.listid, list])).values() // Ensure unique listid
															].map((list) => (
																<MenuItem key={list.listid} value={list.listid}>
																{list.listname} {/* Display list name, but value is listid */}
																</MenuItem>
															))}
															</Select>
													</div>
												</div>
												<div className="col-12">
													<label>Status</label>
													<div className="select-input-form">
													{/* Bootstrap Icon */}
													<i className="bi bi-toggle-off"></i>
													<Select
															className="input-form"
															style={{marginTop: '0', height: '1.5vw', border: 'none'}}
															disableUnderline
															fullWidth
															value={Downloadstatus}
															onChange={handleDownloadContactStatusbyChange}
															input={<InputBase />}
														>
															<MenuItem value="">All</MenuItem>
															<MenuItem value="active">Active</MenuItem>
															<MenuItem value="inactive">InActive</MenuItem>
														</Select>
													</div>
												</div>
												{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') && (
												<div className="col-12">
													<label>Segment</label>
													<div className="select-input-form">
													{/* Bootstrap Icon */}
													<i className="bi bi-segmented-nav"></i>
													<Select
															className="input-form"
															style={{marginTop: '0', height: '1.5vw', border: 'none'}}
															disableUnderline
															fullWidth
															value={Downloadsegment}
															onChange={handleDownloadSegmentbyChange}
															input={<InputBase />}
														>
															<MenuItem value="">All</MenuItem>
															<MenuItem value="green">Green</MenuItem>
															<MenuItem value="red">Red</MenuItem>
															<MenuItem value="orange">Orange</MenuItem>
														</Select>
													</div>
												</div>
												)}
										</div>		
						</div>
						
						
					</div>	
					)}
					{InputApiArea === 'false' && (	
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Contacts Exporting!</h2>
							  <h2 className="secondary-description-modal text-center">Your contacts are being downloaded</h2>
							  <center><button type="button" className="primary-button float-center" onClick={handleExportModalClose} >Okay</button></center>
							</div>
						</div>
					)}
						
					</div>
					
					{InputApiArea === 'true' && (
					<div className="modal-footer ">
						<button type="button" style={{fontSize: "0.8vw"}} className="secondary-button mt-0" onClick={handleExportModalClose}>Cancel</button>
						<button type="button" style={{fontSize: "0.8vw"}} className="primary-button mt-0" onClick={handleExportList}>Export Contacts</button>
					</div>
					)}
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  
		<Dialog open={open} onClose={handleCloseDeleteModal}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Contact? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} className="text-secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} className="text-danger">
                        Delete
                    </Button>
                </DialogActions>
        </Dialog>

		
		<Dialog open={openDeleteFolderModal} onClose={handleCloseDeleteFolderModal}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
						Are you sure you want to delete this folder? All lists and contacts stored in this folder will also be removed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteFolderModal} className="text-secondary">
                        Cancel
                    </Button>
                    <Button onClick={deleteByFolderId} className="text-danger">
                        Delete
                    </Button>
                </DialogActions>
        </Dialog>

		
		<Dialog open={openDeleteListModal} onClose={handleCloseDeleteListModal}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
						Are you sure you want to delete this list? All contacts stored in this list will also be removed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteListModal} className="text-secondary">
                        Cancel
                    </Button>
                    <Button onClick={deleteByListId} className="text-danger">
                        Delete
                    </Button>
                </DialogActions>
        </Dialog>

		



		<Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
    </SideBar>
  );
};

export default AudienceOverview;
