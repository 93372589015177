import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { useSearchParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../../css/private/create-email-template-drag-drop-editor.css';
import { Select, MenuItem, Box, Typography } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';

const ItemTypes = {
  ELEMENT: 'element',
};

const DraggableElement = ({ element }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ELEMENT,
    item: { element },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      className="element"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <img className="element-icon" src={element.icon} alt={element.title} />
      <h6 className="element-title">{element.title}</h6>
      <i className="bi bi-grip-horizontal"></i>
    </div>
  );
};


const DroppedElement = ({ element, index, onMoveUp, onMoveDown, onRemove, onEdit }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleContentChange = (e) => {
    const newContent = e.target.innerText;
    onEdit(index, { ...element, content: newContent });
  };

  const renderElement = () => {
    // Default styles for each element type
    const defaultStyles = {
      button: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        fontSize: '18px',
        display:'block',
        width: '25%',
        float: 'center',
        color: '#ffffff',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      input: {
        padding: '8px 12px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      },
      img: {
        width: '200px',
        height: 'auto',
        display: 'block', // Ensures styles like width are applied
      },
      // Add more default styles here for other elements
    };
  
    const elementStyle = element.styles || {};
  
    // Combine default styles with element-specific styles
    const combinedStyles = {
      ...defaultStyles[element.type], // Merge default style for the element type
      ...elementStyle, // Override with custom element styles
    };
  
    switch (element.type) {
      case 'h1':
        return (
          <h1
            style={combinedStyles}
            contentEditable={element.editable || false}
            suppressContentEditableWarning={true}
            onBlur={handleContentChange}
          >
            {element.content}
          </h1>
        );
      case 'p':
        return (
          <p
            style={combinedStyles}
            contentEditable={element.editable || false}
            suppressContentEditableWarning={true}
            onBlur={handleContentChange}
          >
            {element.content}
          </p>
        );
      case 'button':
        return <button style={combinedStyles}>{element.content}</button>;
        
        case 'logo':
          return <img src={element.src} style={combinedStyles} alt="Logo" />;
        
        case 'image':
          return <img src={element.src} style={combinedStyles} alt="Image" />;
        
      case 'input':
        return <input style={combinedStyles} value={element.content} />;
      default:
        return (
          <div
            style={combinedStyles}
            contentEditable={element.editable || false}
            suppressContentEditableWarning={true}
            onBlur={handleContentChange}
          >
            {element.content}
          </div>
        );
    }
  };
  

  return (
    <div
      className="dropped-element"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        border: isHovered ? '0.2vw solid rgba(2, 96, 105)' : '1px solid transparent',
        position: 'relative',
        cursor: isHovered ? 'pointer' : '',
        padding: '10px',
        borderRadius: '5px',
        transition: 'border 0.1s',
      }}
    >
      {renderElement()}
      {isHovered && (
        <div
          className="element-controls"
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            display: 'flex',
            gap: '5px',
            zIndex: 1,
          }}
        >
          <button onClick={() => onEdit(index)} title="Edit">
            ✏️
          </button>
          <button onClick={() => onMoveUp(index)} disabled={index === 0} title="Move Up">
            ⬆️
          </button>
          <button onClick={() => onMoveDown(index)} title="Move Down">
            ⬇️
          </button>
          <button onClick={() => onRemove(index)} title="Remove">
            ❌
          </button>
        </div>
      )}
    </div>
  );
};





const DropArea = ({ onDrop, droppedElements, onMoveUp, onMoveDown, onRemove, onEdit }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ELEMENT,
    drop: (item) => onDrop(item.element),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      className="canva-area"
      ref={drop}
      style={{
        border: '2px dashed #ccc',
        backgroundColor: isOver ? '#f5f5f5' : 'white',
        minHeight: '400px',
        padding: '10px',
      }}
    >
      {droppedElements.map((element, index) => (
        <DroppedElement
          key={index}
          element={element}
          index={index}
          onMoveUp={onMoveUp}
          onMoveDown={onMoveDown}
          onRemove={onRemove}
          onEdit={onEdit} 
        />
      ))}
    </div>
  );
};


const CreateEmailTemplateDragDropEditor = () => {

  const [selectedElementIndex, setSelectedElementIndex] = useState(null); // Track the selected element
  const [selectedElementType, setSelectedElementType] = useState(null);
  const [elementStyles, setElementStyles] = useState({
    fontSize: '16px', // Default style values
    color: '#ff0000',
    border: 'none',
    textAlign: 'left',
  });

  const handleEdit = (index) => {
    setSelectedElementIndex(index);
    const selectedElement = droppedElements[index];
    setSelectedElementType(selectedElement.type); // Set the element type
  
    // If element has styles, use them, otherwise default to empty styles
    setElementStyles(selectedElement.styles || {});
  };
  

  

  // Handle changes to styles
  const handleStyleChange = (e) => {
    const { name, value } = e.target;
    setElementStyles((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Apply the styles to the selected element when Save is clicked
  const handleSaveStyles = () => {
    const newElements = [...droppedElements];
    newElements[selectedElementIndex] = {
      ...newElements[selectedElementIndex],
      styles: elementStyles,
    };
    setDroppedElements(newElements);
    setSelectedElementIndex(null); // Deselect after saving
  };

  const handleSaveStylesonChange = () => {
    const newElements = [...droppedElements];
    newElements[selectedElementIndex] = {
      ...newElements[selectedElementIndex],
      styles: elementStyles,
    };
    setDroppedElements(newElements);
  };
  
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('campaign_id');
  const template = searchParams.get('template');
  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');
  const [droppedElements, setDroppedElements] = useState([]);

  const { TemplateId } = useParams();
  
  const handleDrop = (element) => {
    let newElement = {};
    switch (element.title) {
      case 'Title':
        newElement = { type: 'h1', content: 'Title Goes Here',  editable: true};
        break;
      case 'Text':
        newElement = { type: 'p', content: 'Your text goes here...' , editable: true};
        break;
        case 'Button':
        newElement = { type: 'button', content: 'Click Me' };
        break;
        case 'Logo':
          newElement = { type: 'logo', src: 'https://app.aadow.com/imgs/logo-placeholder.jpg' };
          break;
        case 'Image':
          newElement = { type: 'image', src: 'https://app.aadow.com/imgs/placeholder.webp' };
          break;
      default:
        newElement = { type: 'div', content: 'New Element' };
    }
    setDroppedElements((prev) => {
      const updatedElements = [...prev, newElement];
      console.log(updatedElements); // Log to ensure the state is updated
      return updatedElements;
    });
};


  const handleMoveUp = (index) => {
    if (index > 0) {
      const newElements = [...droppedElements];
      const temp = newElements[index - 1];
      newElements[index - 1] = newElements[index];
      newElements[index] = temp;
      setDroppedElements(newElements);
    }
  };

  const handleMoveDown = (index) => {
    if (index < droppedElements.length - 1) {
      const newElements = [...droppedElements];
      const temp = newElements[index + 1];
      newElements[index + 1] = newElements[index];
      newElements[index] = temp;
      setDroppedElements(newElements);
    }
  };

  const handleRemove = (index) => {
    setSelectedElementIndex(null);
    const newElements = droppedElements.filter((_, i) => i !== index);
    setDroppedElements(newElements);
  
    // Deselect the element when it's removed
  };
  
  // Close dropdown when clicking outside of it
  useEffect(() => {
    document.title = 'Email Template - Drag Drop Editor | Aadow';
  }, []);

  const elements = [
    { title: 'Title', icon: 'https://app.aadow.com/svg/title.svg' },
    { title: 'Text', icon: 'https://app.aadow.com/svg/text.svg' },
    { title: 'Button', icon: 'https://app.aadow.com/svg/button.svg' },
    { title: 'Logo', icon: 'https://app.aadow.com/svg/logo.svg' },
    { title: 'Image', icon: 'https://app.aadow.com/svg/image.svg' },
    { title: 'Social', icon: 'https://app.aadow.com/svg/social.svg' },
    { title: 'Header', icon: 'https://app.aadow.com/svg/header.svg' },
    { title: 'Footer', icon: 'https://app.aadow.com/svg/footer.svg' },
    { title: 'Divider', icon: 'https://app.aadow.com/svg/divider.svg' },
    { title: 'Spacer', icon: 'https://app.aadow.com/svg/spacer.svg' },
  ];

  
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  
  useEffect(() => {
    const fetchEmailCampaign = async () => {
        try {
            const response = await fetch(
                `${ServerApiDomain}/api/emailtemplates/${TemplateId}/${CookieAccountId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.type === 'text-editor') {
              let url = `/create/email-template/text-editor/${TemplateId}?`;
              if (campaignId) {
                  url += `campaign_id=${campaignId}&`;
              }

              // Add template if it exists
              if (template) {
                  url += `template=${template}&`;
              }

              // Remove trailing '&' or '?' if no parameters were added
              url = url.endsWith('&') ? url.slice(0, -1) : url;
              url = url.endsWith('?') ? url.slice(0, -1) : url;

              // Redirect to the constructed URL
              window.location.href = url;
            }else if (data.type === 'code-editor') {
            let url = `/create/email-template/code-editor/${TemplateId}?`;
              if (campaignId) {
                  url += `campaign_id=${campaignId}&`;
              }

              // Add template if it exists
              if (template) {
                  url += `template=${template}&`;
              }

              // Remove trailing '&' or '?' if no parameters were added
              url = url.endsWith('&') ? url.slice(0, -1) : url;
              url = url.endsWith('?') ? url.slice(0, -1) : url;

              // Redirect to the constructed URL
              window.location.href = url;
            }

        } catch (error) {
            console.log('Fetch error:', error);
            setError('An error occurred while fetching data.');
            setLoading(false);
        }
    };

    fetchEmailCampaign();
}, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="page-content">
        <div className="create-drag-drop-template-body">
          <header>
            <div className="row">
              <div className="col-4">
                <button className="back-button secondary-button ms-2 me-2">
                  <i className="bi bi-arrow-left me-2"></i> Back to Templates
                </button>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="template-title"
                  value="New Product Annoucement of 26th Jan"
                ></input>
              </div>
              <div className="col-4 text-end">
                <button className="publish-button mt-0 primary-button ms-2">
                  <i className="bi bi-megaphone me-2"></i> Publish
                </button>
              </div>
            </div>
          </header>

          <div className="semi-header">
            <div className="row">
              <div className="col-6">
                <a href="" className="active-tab">Elements</a>
                <a href="" className="tab"><i class="bi bi-lightning"></i> AMP</a>
                <a href="" className="tab">Body Style</a>
              </div>
              <div className="col-6 text-end">
                <button className="secondary-outline-button me-2 undo-redo">
                  <i className="bi bi-arrow-counterclockwise"></i>
                </button>
                <button className="secondary-outline-button ms-2 me-2 undo-redo">
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
                <button className="secondary-outline-button ms-2 me-2">
                  <i className="bi bi-eye me-2"></i> Preview
                </button>
                <button className="secondary-button ms-2 me-2">
                  <i className="bi bi-send me-2"></i> Send Test
                </button>
              </div>
            </div>
          </div>

          <div className="editor-area">
            <div className="editor-container">
              
              <div className="left-section">
                <div className="element-area">
                  <div className="row">
                    {elements.map((element, index) => (
                      <div className="col-4" key={index}>
                        <DraggableElement element={element} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="right-section">
                <DropArea
                  onDrop={handleDrop}
                  droppedElements={droppedElements}
                  onMoveUp={handleMoveUp}
                  onMoveDown={handleMoveDown}
                  onRemove={handleRemove}
                  onEdit={handleEdit}  
                />
              
              {selectedElementIndex !== null && (
              <div className='styling-area'>
                <div className="style-editor">
                      <h3 className='style-editor-heading'>Style Element</h3>
                      <div className='ediging-input-area'>
                          <label>Font Size:</label>
                          <Select
                            name="fontSize"
                            style={{width: '100%', height: '1.8vw'}}
                            value={elementStyles.fontSize}
                            onChange={handleStyleChange}
                          >
                            <MenuItem key="8px" value="8px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">8px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="10" value="10px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">10px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="14px" value="14px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">14px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="18px" value="18px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">18px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="24px" value="24px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">24px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="36px" value="36px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">36px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="42px" value="42px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">42px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="48px" value="48px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">48px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="60px" value="60px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">60px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="72px" value="72px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">72px</Typography>
                            </Box>
                            </MenuItem>
                          </Select>
                          <label>Font Weight:</label>
                          <Select
                            name="fontWeight"
                            style={{width: '100%', height: '1.8vw'}}
                            value={elementStyles.fontWeight}
                            onChange={handleStyleChange}
                          >
                            <MenuItem key="normal" value="normal" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">Light</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="normal" value="" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">Normal</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="bold" value="bold" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">Bold</Typography>
                            </Box>
                            </MenuItem>
                          </Select>
                          <label>Color:</label>
                            <input
                              type="color"
                              name="color"
                              value={elementStyles.color}
                              onChange={handleStyleChange}
                            />
                          
                      
                      {/* Conditional styling options */}
                      {selectedElementType === 'button' && (
                        <>
                          <label>
                          Width:
                          </label>
                          <Select
                            name="width"
                            style={{width: '100%', height: '1.8vw'}}
                            value={elementStyles.width}
                            onChange={handleStyleChange}
                          >
                            <MenuItem key="25%" value="25%" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">25%</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="50%" value="50%" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">50%</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="75%" value="75%" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">75%</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="100%" value="100%" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">100%</Typography>
                            </Box>
                            </MenuItem>

                          </Select>
                          <label>
                            Button Background Color:
                            </label>
                            <input
                              type="color"
                              name="backgroundColor"
                              value={elementStyles.backgroundColor}
                              onChange={handleStyleChange}
                            />
                          <label>
                            Button Padding:
                          </label>
                          <Select
                            name="padding"
                            style={{width: '100%', height: '1.8vw'}}
                            value={elementStyles.textAlign}
                            onChange={handleStyleChange}
                          >
                            <MenuItem key="5px" value="5px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">5px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="10px" value="10px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">10px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="20px" value="20px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">20px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="25px" value="25px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">25px</Typography>
                            </Box>
                            </MenuItem>
                            <MenuItem key="50px" value="50px" className="select-box-item">
                            <Box display="flex" alignItems="center" className="select-box-box">
                              <Typography className="select-box-typography">50px</Typography>
                            </Box>
                            </MenuItem>
                          </Select>
                            
                        </>
                      )}

                      {selectedElementType === 'h1' || selectedElementType === 'p' ? (
                        <>
                         <label>
                        Text Align:
                        </label>
                        <Select
                          name="textAlign"
                          style={{width: '100%', height: '1.8vw'}}
                          value={elementStyles.textAlign}
                          onChange={handleStyleChange}
                        >
                          <MenuItem key="left" value="left" className="select-box-item">
                          <Box display="flex" alignItems="center" className="select-box-box">
                            <Typography className="select-box-typography">Left</Typography>
                          </Box>
                          </MenuItem>
                          <MenuItem key="center" value="center" className="select-box-item">
                          <Box display="flex" alignItems="center" className="select-box-box">
                            <Typography className="select-box-typography">center</Typography>
                          </Box>
                          </MenuItem>
                          
                          <MenuItem key="right" value="right" className="select-box-item">
                          <Box display="flex" alignItems="center" className="select-box-box">
                            <Typography className="select-box-typography">Right</Typography>
                          </Box>
                          </MenuItem>
                        </Select>

                        </>
                      ) : null}
                      <div className='d-block'>
                      <button onClick={handleSaveStyles}>Save Styles</button>
                      </div>
                      </div>
                    </div>
              </div>
              )}
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </DndProvider>
  );
};

export default CreateEmailTemplateDragDropEditor;
