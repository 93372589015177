import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/create-email-campaign.css';
import SideBar from './../components/SideBar';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Select, MenuItem, Box, Typography, InputBase } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactSpeedometer from "react-d3-speedometer";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const EmailApi = () => {
	
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
	const navigate = useNavigate();
	const [timer, setTimer] = useState(3);
	const [listening, setListening] = useState(false);
    const [cpuUsage, setcpuUsage] = useState(0);
    const [AbType, setAbType] = useState("");
    const [AttachmentStatus, setAttachmentStatus] = useState("");
    const [spamMeterLabel, setspamMeterLabel] = useState("");
    const [CampaignStatus, setCampaignStatus] = useState("");
    const [memoryUsage, setmemoryUsage] = useState(0);
	const [FullPageloading, setFullPageloading] = useState(false);
	const [selectedOption, setSelectedOption] = useState("");
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const [EmptyError, setEmptyError] = useState("no"); // Set default to '#'
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [ScheduledError, setScheduledError] = useState("");
	const CookietId = Cookies.get('current_Id');
	const CookieAccountId = Cookies.get('current_accountId');
	const CookieAccountName = Cookies.get('current_accountName');
	const CookieAccountEmail = Cookies.get('current_accountEmail');
	const CurrentPlanName = Cookies.get('CurrentPlanName');
	const CurrentAvailableCredits = Cookies.get('CurrentAvailableCredits');
	const { CampaignId } = useParams();
	const [HoursSelectValue, setHoursSelectValue] = useState('');
	const [scheduleDate, setScheduleDate] = useState('');
	const today = new Date().toISOString().split("T")[0];
	const [selectedItems, setSelectedItems] = useState([]);
	const [ListNames, setListNames] = useState("");
	const [selectedListNames, setSelectedListNames] = useState("");
	const [ExistselectedListNames, setExistselectedListNames] = useState(false);
	const [contactCounts, setContactCounts] = useState("");
	const [selectedListIds, setselectedListIds] = useState("");
	const [totalContacts, setTotalContacts] = useState(0); // New state for the total sum

	const [totalCredits, settotalCredits] = useState(CurrentAvailableCredits);
	const [SenderName, setSenderName] = useState('');
	const [GetSenderEmail, setGetSenderEmail] = useState('');
	const [GetSenderName, setGetSenderName] = useState('');
	const [GetSubjectLine, setGetSubjectLine] = useState('');
	const [GetSubjectLineB, setGetSubjectLineB] = useState('');
	const [GetPreviewText, setGetPreviewText] = useState('');
	const [GetEmailTemplate, setGetEmailTemplate] = useState('');
	const [GetEmailTemplateB, setGetEmailTemplateB] = useState('');
	const [GetContactLists, setGetContactLists] = useState('');
	const [TemplateName, setTemplateName] = useState('');
	const [ReplyTo, setReplyTo] = useState('');
	const [Subject, setSubject] = useState('');
	const [SubjectB, setSubjectB] = useState('');
	const [Preview, setPreview] = useState('');
	const [Template, setTemplate] = useState('');
	const [TemplateB, setTemplateB] = useState('');
	const [SenderNameError, setSenderNameError] = useState(false);
	const [ReplyToError, setReplyToError] = useState(false);
	const [SubjectError, setSubjectError] = useState(false);
	const [SubjectBError, setSubjectBError] = useState(false);
	const [PreviewError, setPreviewError] = useState(false);
	const [TemplateError, setTemplateError] = useState(false);
	const [TemplateBError, setTemplateBError] = useState(false);
	const SenderNameRef = useRef(null);
	const ReplyToRef = useRef(null);
	const SubjectRef = useRef(null);
	const SubjectBRef = useRef(null);
	const PreviewRef = useRef(null);
	const TemplateRef = useRef(null);
	const TemplateBRef = useRef(null);
	const TemplateNameRef = useRef(null);
	const [ErrorSenderName, setErrorSenderName] = useState('');
	const [ErrorSelectList, setErrorSelectList] = useState('');
	const [ErrorSenderEmail, setErrorSenderEmail] = useState('');
	const [ErrorSubject, setErrorSubject] = useState('');
	const [ErrorSubjectB, setErrorSubjectB] = useState('');
	const [ErrorTemplateB, setErrorTemplateB] = useState('');
	const [ErrorPreview, setErrorPreview] = useState('');
	const [ErrorTemplate, setErrorTemplate] = useState('');
	const [currentStep, setCurrentStep] = useState(0); // Step state
	const [errorMessage, setErrorMessage] = useState(""); // State for error message 
	const [showContent, setShowContent] = useState(false);
	const [showContentTwo, setShowContentTwo] = useState(false);
	const namesArray = ListNames.split(', ').map(name => name.trim());
	const countsArray = contactCounts.split(', ').map(count => count.trim());
	const idsArray = selectedListIds.split(', ').map(id => id.trim());
	const [success, setSuccess] = useState(null);	

    let eventSource = undefined;

    useEffect(() => {
        if (!listening) {
            eventSource = new EventSource(`${ServerApiDomain}/event/resources/usage`);
            eventSource.onmessage = (event) => {
                const usage = JSON.parse(event.data);
                setcpuUsage(usage.cpuUsage);
                setmemoryUsage(usage.memoryUsage);
				setspamMeterLabel("");
            }
            eventSource.onerror = (err) => {
                console.error("EventSource failed:", err);
                eventSource.close();
            }
            setListening(true);
        }
        return () => {
            eventSource.close();
            console.log("event closed");
        };
    }, []);

  

  const handleDateChange = (event) => {
    setScheduleDate(event.target.value);
  };

  
  const [MinutesSelectValue, setMinutesSelectValue] = useState('');

  const HoursSelectChangeHandle = (event) => {
	setHoursSelectValue(event.target.value)
  };

  
  const MinutesSelectChangeHandle = (event) => {
	setMinutesSelectValue(event.target.value)
  };
  
  useEffect(() => {
		fetchEmailCampaign();
	}, []);

  const fetchEmailCampaign = async () => {
		try {
		const response = await fetch(`${ServerApiDomain}/api/campaigns/${CookieAccountId}/campaign/${CampaignId}`, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			
			alert('Network response was not ok');
		}

		
		
		const data = await response.json();

		if (data.status === "sent") {
            window.location.href = `/campaigns/email/${CampaignId}`;
        }

		// When setting uploaded files
		if(data.attachments === "" || data.attachments === null){
			setAttachmentStatus(true);
		}
		const attachments = data.attachments ? data.attachments.split(",") : [];
		setUploadedFiles({
			attachment1: {
				fullPath: attachments[0] || "", // Full path for API usage
				displayName: attachments[0] ? attachments[0].split('\\').pop() : "", // Extracted filename for display
			},
			attachment2: {
				fullPath: attachments[1] || "",
				displayName: attachments[1] ? attachments[1].split('\\').pop() : "",
			},
		});

		if(data.attachments !== null){
			setShowContent(true);
		}
		if(data.contactLists !== ""){
		setExistselectedListNames(true);
		}
		setSenderEmailselectedValue(data.fromEmail);
		setSenderName(data.fromName);
		setReplyTo(data.replyEmail);
		setTemplateName(data.campaignName);
		setselectedListIds(data.totalContactListsId);
		setSelectedListNames(data.contactLists);
		setContactCounts(data.totalContacts);
		setTemplate(data.emailTemplate);
		setTemplateB(data.emailTemplateB);
		setSubject(data.subject);
		setSubjectB(data.subjectB);
		setPreview(data.preview);
		setListNames(data.contactLists);
		setGetSenderEmail(data.fromEmail);
		setGetSenderName(data.fromName);
		setListNames(data.contactLists);
		setGetPreviewText(data.preview);
		setGetSubjectLine(data.subject);
		setGetSubjectLineB(data.subjectB);
		setGetEmailTemplate(data.emailTemplate);
		setGetEmailTemplateB(data.emailTemplateB);
		setAbType(data.abType);
		setGetContactLists(data.contactLists);
		setCampaignStatus(data.status);
		setSegmentselectedValue(data.segments);
		

		const contactsArray = data.totalContacts.split(', ').map(Number);
        const totalContactsSum = contactsArray.reduce((accumulator, current) => accumulator + current, 0);
        setTotalContacts(totalContactsSum);

		if(data.fromEmail === ""){
			setCurrentStep(0);
		}else{
			setCurrentStep(3);
		}
		if (data.SenderEmailselectedValue !== "" || data.SenderEmailselectedValue !== null) {
			spamMeterChange();
		}
		
		setLoading(false);  // Set loading to false once data is fetched
		
		} catch (error) {
			
		setEmptyError("yes");
		setError("Fetch error:", error);
		setLoading(false);
		}
	};

	const deleteScheduledEmailContact = async () => {
		setFullPageloading(true);
		const url = `${ServerApiDomain}/scheduled-email-contacts/delete?campaignId=${CampaignId}&username=${CookieAccountId}`;
		
		try {
		  const response = await fetch(url, {
			method: "DELETE",
		  });
	  
		  if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		  }
	  
		  const data = await response.json(); // Assuming the API returns JSON
		  
		  SubmitDraftCampaignStatus();
		} catch (error) {
			SubmitDraftCampaignStatus();
		}
	  };	

	const SubmitDraftCampaignStatus = async () => {
			const updatedData = {
				"username": CookieAccountId,      // Replace with actual variable
				"status": "draft"
			};

			try {
				const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${CampaignId}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(updatedData)
				});

				if (response.ok) {
					setGetSenderEmail(SenderEmailselectedValue);
					setGetSenderName(SenderName)
					let result = {};
					try {
						result = await response.json();
					} catch (error) {
						console.error('Error parsing response JSON:', error);
					}
					window.location.reload();
					

				} else {
				}
			} catch (error) {
			}
  	};

	  

  const [isSidenavOpen, setIsSidenavOpen] = useState(false);

    // Function to handle schedule click
    const handleSchedule = () => {
        setIsSidenavOpen(true); // Open the sidenav
    };

    // Function to handle overlay click
    const handleOverlayClick = () => {
        setIsSidenavOpen(false); // Close the sidenav
    };
	
  const formatNumberWithCommas = (number) => {
	  return new Intl.NumberFormat().format(number);
	};

  // List data (mimics what you want in the MenuItems)
  const [menuItems, setMenuItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  
  
  const [SegmentselectedValue, setSegmentselectedValue] = useState("");

  // Fetch data whenever `SegmentselectedValue` changes
  useEffect(() => {
	fetchData();
  }, [SegmentselectedValue]);
  
  const fetchData = async () => {
	try {
	  const response = await fetch(`${ServerApiDomain}/api/email-contacts/distinct-lists?username=${CookieAccountId}&status=active&segment=${SegmentselectedValue}`);
	  const data = await response.json();
  
	  // Map the API response to the format you need
	  const formattedItems = data.map(item => ({
		label: item.listname,  // List name from the API
		value: item.listid,    // List ID or another unique value
		folder: item.folder,   // Folder name
		count: item.totalContacts  // Number of contacts from the API
	  }));
  
	  // Update the state with the fetched data
	  setMenuItems(formattedItems);
	} catch (error) {
	  console.error("Error fetching data:", error);
	}
  };
  
  const handleSegmentChange = (event) => {
	setSegmentselectedValue(event.target.value); // This will trigger the `useEffect`
	changeReciepents(); // Any additional logic can remain here
  };
  
  // Function to handle when a new item is selected
  const handleSelectChange = (event) => {
	  setErrorSelectList("");
    const selectedValue = event.target.value;
    const selectedItem = menuItems.find(item => item.value === selectedValue);

    // Check if the selected item already exists
    if (selectedItem && !selectedItems.some(item => item.value === selectedItem.value)) {
      const updatedItems = [...selectedItems, selectedItem];
      setSelectedItems(updatedItems);

      // Update the input values (list names and contact counts)
      const listNames = updatedItems.map(item => item.label).join(", ");
      const listIds = updatedItems.map(item => item.value).join(", ");
      const counts = updatedItems.map(item => item.count).join(", ");
      const total = updatedItems.reduce((acc, item) => acc + item.count, 0); // Calculate the total

      setSelectedListNames(listNames);
      setContactCounts(counts);
	  setselectedListIds(listIds);
      setTotalContacts(total); // Update the totalContacts state
    }
  };

  // Function to remove a selected item
  const removeSelectedItem = (itemToRemove) => {
	  setErrorSelectList("");
    const updatedItems = selectedItems.filter(item => item !== itemToRemove);
    setSelectedItems(updatedItems);

    // Update the input values (list names and contact counts)
    const listNames = updatedItems.map(item => item.label).join(", ");
	const listIds = updatedItems.map(item => item.value).join(", ");
    const counts = updatedItems.map(item => item.count).join(", ");
    const total = updatedItems.reduce((acc, item) => acc + item.count, 0); // Recalculate the total

    setSelectedListNames(listNames);
    setContactCounts(counts);
	setselectedListIds(listIds);
    setTotalContacts(total); // Update the totalContacts state
  };
  
  const [SenderEmailselectedValue, setSenderEmailselectedValue] = useState("");
  
  
  // Function to handle the change event
  	const handleSenderEmailSelectChange = (event) => {
		const selectedEmail = event.target.value; // Get the selected email

		setSenderEmailselectedValue(selectedEmail); // Update the state with the selected email
		console.log(selectedEmail); // Log the selected value for debugging

		setGetSenderEmail(selectedEmail); // Assuming you also need this value for some other purpose
		setSenderName("");
		// Check if the selected email is not the 'add-sender' option
		if (selectedEmail === "add-sender") {
			window.location.href = `/sender-authentication#single-sender`; // Navigate to a new sender page
		} else {
			const selectedEmailObj = emails.find((email) => email.email === selectedEmail);
			
			// If a valid email object is found, set the senderName in the input
			if (selectedEmailObj) {
				setSenderName(selectedEmailObj.senderName); // Update the senderName input
			}
		}
	};
  
  // Function to handle the change event
  
  
  
  

  // Function to handle click and toggle the content visibility
  const toggleContent = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowContent((prevState) => !prevState); // Toggle the state
  };
  const toggleContentTwo = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowContentTwo((prevState) => !prevState); // Toggle the state
  };
  const changeReciepents = () => {
	setExistselectedListNames(false);
	setTotalContacts("0");
	setselectedListIds("");
	setselectedListIds("");
	setContactCounts("");
  };


	  useEffect(() => {
		document.title = 'New Email Campaign | Aadow';
	  }, []);
  
	  setInterval(() => {
		if (SenderName !== "") {
		setGetSenderName(SenderName);
		}
		if (Subject !== "") {
	    setGetSubjectLine(Subject);
		}
		if (Preview !== "") {
		setGetPreviewText(Preview);
		}
		if (SenderEmailselectedValue !== "") {
		setGetSenderEmail(SenderEmailselectedValue);
		}
	  }, 30000);
  
  const handleSenderNameChange = () => {
	  
  }
     
  const handleSubjectChange = () => {
  }
  
  const handlePreviewChange = () => {
	  setGetPreviewText(Preview);
  }



  	const handleScheduleCampaignNow = async () => {
		
		setFullPageloading(true);
		const now = new Date();
		const fiveMinutesLater = new Date(now.getTime() + 5 * 60 * 1000);

		// Convert to IST (UTC+5:30)
		const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
		const istTime = new Date(fiveMinutesLater.getTime() + istOffset);
		
		
		const updatedData = {
			"username": CookieAccountId,  // Replace with actual variable
			"status": "scheduled",
			"scheduledDateTime": istTime
		};

	try {
		const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${CampaignId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(updatedData)
		});

		if (response.ok) {
			setGetSenderEmail(SenderEmailselectedValue);
			setGetSenderName(SenderName)
			let result = {};
			try {
				result = await response.json();
			} catch (error) {
				console.error('Error parsing response JSON:', error);
			}
			SubmitScheduledCampaignStatus();
			
		} else {
			setFullPageloading(false);
		}
	} catch (error) {
		
		setFullPageloading(false);
	}
	};

	const handleScheduleCampaignLater = async () => {
		setScheduledError("");
		if(scheduleDate === ""){
			setScheduledError("Please select a date");
			return;
		}
		if(HoursSelectValue === ""){
			setScheduledError("Please select hour");
			return;
		}

		if(MinutesSelectValue === ""){
			setScheduledError("Please select minutes");
			return;
		}
		handleScheduleCampaignLaterFinal();
	};

	const handleScheduleCampaignLaterFinal = async () => {
		
		setFullPageloading(true);
		
		
		const updatedData = {
			"username": CookieAccountId,  // Replace with actual variable
			"status": "scheduled",
			"scheduledDateTime": scheduleDate+"T"+HoursSelectValue+":"+MinutesSelectValue+":00"
		};

	try {
		const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${CampaignId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(updatedData)
		});

		if (response.ok) {
			setGetSenderEmail(SenderEmailselectedValue);
			setGetSenderName(SenderName)
			let result = {};
			try {
				result = await response.json();
			} catch (error) {
				console.error('Error parsing response JSON:', error);
			}
			SubmitScheduledCampaignStatus();
			
		} else {
			setFullPageloading(false);
		}
	} catch (error) {
		
		setFullPageloading(false);
	}
	};

    const SubmitScheduledCampaignStatus = async () => {
		setFullPageloading(true);
		try {
			const response = await fetch(`http://app.aadow.com/aadow/ScheduleEmailCampaign.php`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded', // Setting content type for form data
				},
				body: new URLSearchParams({ // Using URLSearchParams to format the body
					username: CookieAccountId,
					campaign_id: CampaignId,
				}),
			});

			const data = await response.json(); // Parse the JSON response

			 // Log the response for debugging
			
			 setFullPageloading(false);
		} catch (error) {
			console.log('Fetch error: ' + error.message);// Log the error for debugging
		} finally {
			setLoading(false);
		setFullPageloading(false);
		setShow(true);
		const countdown = setInterval(() => {
			setTimer(prevTimer => {
			  if (prevTimer <= 1) {
				clearInterval(countdown);
				navigate('/campaigns/email'); // Change '/target-page' to your desired route
			  }
			  return prevTimer - 1;
			});
		  }, 1000);

		}
	};
  
  const handleSubmit = async (event) => {
		event.preventDefault(); // Prevent the default form submission
			const updatedData = {
				"username": CookieAccountId,      // Replace with actual variable
				"subject": Subject,
				"fromName": SenderName,
				"fromEmail": SenderEmailselectedValue,
				"replyEmail": ReplyTo
			};

			try {
				const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${CampaignId}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(updatedData)
				});

				if (response.ok) {
					setGetSenderEmail(SenderEmailselectedValue);
					setGetSenderName(SenderName)
					let result = {};
					try {
						result = await response.json();
					} catch (error) {
						console.error('Error parsing response JSON:', error);
					}
					console.log(`Campaign updated successfully: ${JSON.stringify(result)}`);
				} else {
					console.log(`Failed to update campaign: ${response.statusText}`);
				}
			} catch (error) {
				console.log(`Error while updating campaign: ${error.message}`);
			}
	};

	const handleSubmitTwo = async (event) => {
		event.preventDefault(); // Prevent the default form submission
			const updatedData = {
				"username": CookieAccountId,      // Replace with actual variable
				"contactLists": selectedListNames,
				"totalContacts": contactCounts,
				"totalContactListsId": selectedListIds,
				"segments": SegmentselectedValue
			};

			try {
				const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${CampaignId}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(updatedData)
				});

				if (response.ok) {
					let result = {};
					try {
						result = await response.json();
						setGetContactLists(selectedListNames);
					} catch (error) {
						console.error('Error parsing response JSON:', error);
					}
					console.log(`Campaign updated successfully: ${JSON.stringify(result)}`);
				} else {
					console.log(`Failed to update campaign: ${response.statusText}`);
				}
			} catch (error) {
				console.log(`Error while updating campaign: ${error.message}`);
			}
	};

	const handleSubmitThree = async (event) => {
		event.preventDefault(); // Prevent the default form submission
			const updatedData = {
				"username": CookieAccountId,      // Replace with actual variable
				"subject": Subject,      // Replace with actual variable
				"subjectB": SubjectB,
				"preview": Preview,
				"emailTemplate": Template,
				"segments": SegmentselectedValue
			};

			try {
				const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${CampaignId}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(updatedData)
				});

				if (response.ok) {
					let result = {};
					try {
						setGetPreviewText(Preview);
						setGetEmailTemplate(Template);
						setGetSubjectLine(Subject);
						if(AbType === "subject"){
							setGetSubjectLineB(SubjectB);
						}else if(AbType === "template"){
							setGetEmailTemplateB(TemplateB);
						}
						result = await response.json();
					} catch (error) {
						console.error('Error parsing response JSON:', error);
					}
					console.log(`Campaign updated successfully: ${JSON.stringify(result)}`);
				} else {
					console.log(`Failed to update campaign: ${response.statusText}`);
				}
			} catch (error) {
				console.log(`Error while updating campaign: ${error.message}`);
			}
	};

	const handleNameUpdate = async (event) => {
		if(TemplateName !== ""){
		event.preventDefault(); // Prevent the default form submission
			const updatedData = {
				"username": CookieAccountId,      // Replace with actual variable
				"campaignName": TemplateName
			};

			try {
				const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${CampaignId}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(updatedData)
				});

				if (response.ok) {
					let result = {};
					try {
						result = await response.json();
					} catch (error) {
						console.error('Error parsing response JSON:', error);
					}
					console.log(`Campaign updated successfully: ${JSON.stringify(result)}`);
				} else {
					console.log(`Failed to update campaign: ${response.statusText}`);
				}
			} catch (error) {
				console.log(`Error while updating campaign: ${error.message}`);
			}
		}
	};

	const validateSubjectLine = (subjectLine) => {
		let score = 0;
	
		// 1. Word count scoring
		const wordCount = subjectLine.trim().split(/\s+/).length;  // Split by whitespace and count words
		if (wordCount < 3) {
			score += 5;  // Too few words
		} else if (wordCount >= 3 && wordCount <= 5) {
			score += 15;  // Just the right amount of words
		} else if (wordCount > 5) {
			score += 20;  // Too many words, could be spammy
		}
	
		// 2. Capitalization Check
		if (subjectLine === subjectLine.toUpperCase()) {
			score -= 5;  // All caps is typically a sign of spam
		}
	
		// 3. Length condition (keeping it between 5 and 15 characters)
		const subjectLength = subjectLine.length;
		if (subjectLength < 5) {
			score -= 5;  // Too short
		} else if (subjectLength >= 5 && subjectLength <= 15) {
			score += 15;  // Ideal length
		} else {
			score += 20;  // Long subject line
		}
	
		return score;
	};


	
	const spamMeterChange = async (event) => {
		// Initialize score as a number, not a string
		let score = 0;  
	
		// Score based on attachment status
		if (AttachmentStatus === false) {
			score += 25;  // If attachment status is false, give a higher score
		} else {
			score += 15;  // If attachment status is true, give a lower score
		}
	
		// Score based on selected segment value
		if(SegmentselectedValue === "green"){
			score += 25;  // Green segment is optimal
		} else if(SegmentselectedValue === "orange"){
			score += 15;  // Orange segment is moderate
		} else if(SegmentselectedValue === "red"){
			score += 5;   // Red segment is risky
		}
	
		// Score based on preview text
		if(GetPreviewText !== ""){
			score += 5;   // If preview text is provided, give a small score boost
		}
	
		// Get subject line score by validating the subject line
		const subjectLineScore = validateSubjectLine(GetSubjectLine);
		score += subjectLineScore;
	
		// Set spam meter label based on the final score
		if (score <= 25) {
			setspamMeterLabel("Poor");  // Low score means poor spam meter
		} else if (score > 25 && score <= 50) {
			setspamMeterLabel("At Risk");  // Moderate score means at risk
		} else if (score > 50 && score <= 75) {
			setspamMeterLabel("Fair");  // Higher score means fair
		} else if (score > 75) {
			setspamMeterLabel("Ideal");  // High score means ideal
		}
	
		// Set CPU usage score (or spam score)
		setcpuUsage(score);
	};
	
	  

  const handleNext = async (event) => {
	
	
	

	if (currentStep === 0) {
        setErrorSenderEmail("");
        setErrorSenderName("");
        setSenderNameError(false);

        if (SenderEmailselectedValue.trim() === '') {
            setErrorSenderEmail("Please select sender email");
            return;
        } else if (SenderName.trim() === '') {
            setErrorSenderName("Please enter a sender name");
            setSenderNameError(true);
            SenderNameRef.current.focus();
            return;
        }

        handleSubmit(event);
		spamMeterChange();
    }
    
	
	if (currentStep === 1) {
		
			setErrorSelectList("");
		if (selectedListNames.trim() === '') {
			setErrorSelectList("Please select aleast 1 contact list");
			return;
		}
		handleSubmitTwo(event);
		spamMeterChange();	
    }

	
	if (currentStep === 2) {
		
			setErrorSubject("");
			setSubjectError(false);
			setErrorSubjectB("");
			setSubjectBError(false);
			setPreviewError("");
			setErrorTemplate("");
			setTemplateError(false);
			setErrorTemplateB("");
			setTemplateBError(false);
		
		if (Subject.trim() === '') {
			setErrorSubject("Please enter a subject line");
			setSubjectError(true);
			SubjectRef.current.focus();
			return;
		}

		if(AbType === 'subject'){
			if (SubjectB.trim() === '') {
				setErrorSubjectB("Please enter a subject line B");
				setSubjectBError(true);
				SubjectBRef.current.focus();
				return;
			}
		}
		
		if (Template.trim() === '') {
			setErrorTemplate("Please select a template");
			setTemplateError(true);
			TemplateRef.current.focus();
			return;
		}

		if(AbType === 'template'){
			if (TemplateB.trim() === '') {
				setErrorTemplateB("Please select template B");
				setTemplateBError(true);
				TemplateBRef.current.focus();
				return;
			}
		}



		
		handleSubmitThree(event);
		spamMeterChange();
		

    }
	

    if (currentStep < 3) spamMeterChange(); setCurrentStep(currentStep + 1);
  };
  
  
   const calculatePercentageUsed = (contacts, credits) => {
    if (credits === 0) return 0; // Prevent division by zero
    return ((contacts / credits) * 100).toFixed(2); // Return percentage formatted to 2 decimal places
  };

  const percentageUsed = calculatePercentageUsed(totalContacts, totalCredits);
  

  const handlePrev = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1); // Go to the previous step
  };
  
  
  const goToStep = (stepNumber) => {
    setCurrentStep(stepNumber); // Directly jump to the step number
  };
  
  	const [emails, setEmails] = useState([]);
	useEffect(() => {
			const fetchEmails = async () => {
				try {
					const response = await fetch(`${ServerApiDomain}/api/senders_email/emails?username=${CookieAccountId}&status=active`);
					const data = await response.json();
					setEmails(data);
				} catch (error) {
					console.error('Error fetching emails:', error);
				}
			};

			fetchEmails();
		}, []);

  	if (loading) {
	}

	if (error) {
	}

	function FileUpload() {
		const [files, setFiles] = useState([]);
		const [error, setError] = useState('');
	  
		const handleFileChange = (event) => {
		  const selectedFiles = event.target.files;
		  if (selectedFiles.length > 2) {
			setError("You can only upload up to 2 files.");
		  } else {
			setFiles(selectedFiles);
			setError('');
		  }
		};
	  
		const handleSubmit = async (event) => {
		  event.preventDefault();
		  
		  if (files.length === 0) {
			setError('Please select files to upload.');
			return;
		  }
	  
		  const formData = new FormData();
		  Array.from(files).forEach((file) => {
			formData.append('files', file);
		  });
	  
		  try {
			const response = await axios.post('/upload', formData, {
			  headers: {
				'Content-Type': 'multipart/form-data',
			  },
			});
	  
			// Handle response (e.g., success message)
			console.log('Files uploaded successfully:', response.data);
		  } catch (error) {
			// Handle error
			setError('Failed to upload files.');
			console.error('Upload error:', error);
		  }
		};
	};


  

	const [files, setFiles] = useState({
		attachment1: null,
		attachment2: null,
	  });
	
	  const [uploadedFiles, setUploadedFiles] = useState({
		attachment1: null,
		attachment2: null,
	  });
	
	  const [uploading, setUploading] = useState({
		attachment1: false,
		attachment2: false,
	  });
	
	  const handleFileChange = async (event, fieldName) => {
		const file = event.target.files[0];
		if (!file) return;
	
		setUploading((prev) => ({ ...prev, [fieldName]: true }));
	
		const formData = new FormData();
		formData.append("username", CookieAccountId); // Replace with actual username
		formData.append("campaignId", CampaignId); // Replace with actual campaignId
		formData.append(fieldName, file);
	
		try {
		  const response = await axios.post(
			`${ServerApiDomain}/api/campaigns/upload-attachments`,
			formData,
			{
			  headers: { "Content-Type": "multipart/form-data" },
			}
		  );
	
		  console.log(response.data);
		  setUploadedFiles((prev) => ({ ...prev, [fieldName]: file.name }));
		  setFiles((prev) => ({ ...prev, [fieldName]: file }));
		  setAttachmentStatus(true);
		  window.location.reload();
		} catch (error) {
		  console.error("Error uploading file:", error);
		} finally {
		  setUploading((prev) => ({ ...prev, [fieldName]: false }));
		}
	  };
	
	  const encodeAttachmentPath = (path) => {
		return path.replace(/\\/g, "%5C");
	  };

	

	  	const encodeAttachmentPathtwo = (path) => {
		return path.replace(/\\/g, "%5C");
		};

		// Example React component
		const handleRemoveFile = async (fileKey) => {
			const username = CookieAccountId;
			const campaignId = CampaignId;
		
			// Get the full path from uploadedFiles state
			const attachmentPath = uploadedFiles[fileKey]?.fullPath;
		
			if (!attachmentPath) return; // Ensure there's a file to remove
		
			try {
				// Encode the attachment path
				const encodedPath = encodeAttachmentPathtwo(attachmentPath);
		
				// Construct the API URL
				const apiUrl = `${ServerApiDomain}/api/campaigns/remove-attachment?username=${username}&campaignId=${campaignId}&attachmentPath=${encodedPath}`;
		
				// Call the API
				const response = await axios.delete(apiUrl);
		
				console.log("Attachment removed successfully:", response.data);
		
				// Safely update state to shift attachments or remove as necessary
				setUploadedFiles((prev) => {
					const updatedFiles = { ...prev };
		
					window.location.reload();
					setAttachmentStatus(false);
					return updatedFiles;
				});
			} catch (error) {
				console.error("Failed to remove attachment:", error.response?.data || error.message);
			}
		};
		
		
		
	

  return (
    <SideBar>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		{EmptyError === 'yes' && (
							<center>
								<div className="empty-error-area">
									<img className="empty-error-image w-25" src="../../iconss/other/no-results.png"  />
									<h2 className="empty-error-heading">No Campaign Found</h2>
									<h6 className="empty-error-subheading" >It looks like there are no campaigns associated with this campaign id.</h6>
								</div>
							</center>
		)}
		{EmptyError === 'no' && (
			<div className="page-content">
			<div className="create-campaign-body">
				
			<input
				type="text"
				ref={TemplateNameRef}
				className="campaign-name-input w-75"
				value={TemplateName}
				onChange={(e) => {
					setTemplateName(e.target.value); // Update state
				}}
				onBlur={handleNameUpdate} // Call handleNameUpdate when input loses focus
				placeholder="Enter campaign name"
			/>
				
		
				<div className="top-bar-navigation">
			
				<div id="step_one" className={`steps ${currentStep === 0 ? 'steps_active' : ''}`}>
				<h5>
				<i className={`bi ${currentStep === 0 ? 'bi-1-circle-fill' : 'bi-1-circle'}`}></i>
				Sender
				
				<i className="bi bi-dash-lg"></i>
				</h5>
				</div>
				
				<div  id="step_two" className={`steps ${currentStep === 1 ? 'steps_active' : ''}`}>
				<h5>
				<i className={`bi ${currentStep === 1 ? 'bi-2-circle-fill' : 'bi-2-circle'}`}></i>
				Recipients
				
				<i className="bi bi-dash-lg"></i>
				</h5>
				</div>
				
				
				<div  id="step_three" className={`steps ${currentStep === 2 ? 'steps_active' : ''}`}>
				<h5>
				<i className={`bi ${currentStep === 2 ? 'bi-3-circle-fill' : 'bi-3-circle'}`}></i>
				Email Content
				
				<i className="bi bi-dash-lg"></i>
				</h5>
				</div>
				
				
				<div  id="step_four" className={`steps ${currentStep === 3 ? 'steps_active' : ''}`}>
				<h5>
				<i className={`bi ${currentStep === 3 ? 'bi-4-circle-fill' : 'bi-4-circle'}`}></i>
				Review & Schedule
				</h5>
				</div>
				</div>
				
				
				<div className="campaign-area">
					<div className="row">
						<div className="col-8">
							<div className="left-side">
							
								{currentStep === 0 && (
								<div className="step-one">
									<div className="secondary-heading-area">
											<h4 className="secondary-heading">Sender Infomration</h4>
												<h6 className="secondary-subheading">Who is sending this email campaign?</h6>
									</div>
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												<h4 className="secondary-heading">Email address <b className="text-danger">*</b></h4>
												<h6 className="secondary-subheading">Choose the email address that appears in the recipient's inbox when they receive your campaign</h6>
											</div>
											<div className="col-6">
											<Select
												className="input-form"
												sx={{ fontSize: '4vw' }}
												disableUnderline
												fullWidth
												value={SenderEmailselectedValue}
												onChange={handleSenderEmailSelectChange} 
												input={<InputBase />}>
												<MenuItem value="" selected></MenuItem>
												{emails.map((email, index) => (
													<MenuItem key={index} value={email.email}>
														{email.email} {email.senderName && <span className="ps-1"> ({email.senderName})</span>}
													</MenuItem>
												))}
												<MenuItem value="add-sender">Add a New Sender</MenuItem>
											</Select>
													{ErrorSenderEmail && <div className="error-message">{ErrorSenderEmail}</div>}
											</div>
										</div>
									</div>
									
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												<h4 className="secondary-heading">Name <b className="text-danger">*</b></h4>
												<h6 className="secondary-subheading">Enter a name (e.g. Your company name) to help recipients recognize you in the inbox.</h6>
											</div>
											<div className="col-6">
											<input 
													type="text" 
													ref={SenderNameRef} 
													className={`input-form w-100 ${SenderNameError ? 'input-error' : ''}`} 
													value={SenderName} 
													onChange={(e) => {
														setSenderName(e.target.value);
														handleSenderNameChange(e.target.value); // Custom handler
													}} 
												/>
												{ErrorSenderName && <div className="error-message">{ErrorSenderName}</div>}
											</div>
										</div>
										<a href="#" className="link-button" onClick={toggleContent}>Advanced Options</a>
									</div>
									
									{showContent && (
									<div className="toggled-content">
										<div className="form-area">
											<div className="row align-items-center">
												<div className="col-6">
													<h4 className="secondary-heading">Reply to</h4>
													<h6 className="secondary-subheading">Any specific email address you would like to get reply on.</h6>
												</div>
												<div className="col-6">
														<input type="text" ref={ReplyToRef} className={`input-form w-100 ${ReplyToError ? 'input-error' : ''}`} value={ReplyTo} onChange={(e) => {
													setReplyTo(e.target.value); // Call a custom handler with the value
													}} placeHolder="[DEFAULT_REPLY_TO]" />
												</div>
											</div>
										</div>
										
										<div className="form-area">
											<div className="row align-items-center">
												<div className="col-6">
													<h4 className="secondary-heading">Attachments</h4>
													<h6 className="secondary-subheading">Add attachments to boost your campaign performance</h6>
												</div>
												<div className="col-6">
												<div className="upload-container row">
												<div className="col-6">
        <div className="file-card">
          {!uploadedFiles.attachment1.displayName ? (
            <div className="file-input-wrapper">
              <input
                type="file"
                id="attachment1"
                className="d-none"
                onChange={(e) => handleFileChange(e, "attachment1")}
              />
              <label htmlFor="attachment1" className="attach-files-button mt-0">
                <i className="bi bi-paperclip pe-2"></i> Choose File
              </label>
            </div>
          ) : (
            <div className="uploaded-file">
              <span className="file-name">{uploadedFiles.attachment1.displayName}</span>
              <button
                className="remove-btn"
                onClick={() => handleRemoveFile("attachment1")}
                aria-label="Remove file"
                title="Remove file"
              >
                &times;
              </button>
            </div>
          )}
          {uploading.attachment1 && <div className="spinner">Uploading...</div>}
        </div>
      </div>
      {uploadedFiles.attachment1.displayName && (
        <div className="col-6">
          <div className="file-card">
            {!uploadedFiles.attachment2.displayName ? (
              <div className="file-input-wrapper">
                <input
                  type="file"
                  id="attachment2"
                  className="d-none"
                  onChange={(e) => handleFileChange(e, "attachment2")}
                />
                <label htmlFor="attachment2" className="attach-files-button mt-0">
                  <i className="bi bi-paperclip pe-2"></i> Choose File
                </label>
              </div>
            ) : (
              <div className="uploaded-file">
                <span className="file-name">{uploadedFiles.attachment2.displayName}</span>
                <button
                  className="remove-btn"
                  onClick={() => handleRemoveFile("attachment2")}
                  aria-label="Remove file"
                  title="Remove file"
                >
                  &times;
                </button>
              </div>
            )}
            {uploading.attachment2 && <div className="spinner">Uploading...</div>}
          </div>
        </div>
      )}
	  </div>	
												</div>
											</div>
										</div>
									</div>
									)}
									

									
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-4">
											</div>
											<div className="col-8 text-end">
												
												<button type="button" onClick={handleNext} className="primary-button ms-2">Next</button>
											</div>
										</div>
									</div>
								</div>
								
								)}
								
								
								{currentStep === 1 && (
								<div className="step-two">
									<div className="secondary-heading-area">
											<h4 className="secondary-heading">Recipients</h4>
												<h6 className="secondary-subheading">Select recipients</h6>
									</div>
									{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') && (
										<div className="form-area">
											<div className="row align-items-center">
												<div className="col-6">
													<h4 className="secondary-heading">Audience Segmentaion<b className="text-danger">*</b></h4>
													<h6 className="secondary-subheading">Choose the Audience Segment you want to use</h6>
												</div>
												<div className="col-6">
													<Select
														className="input-form"
														sx={{ fontSize: '4vw' }}
														disableUnderline
														fullWidth
														defaultValue=""
														value={SegmentselectedValue} // Set the value from state
														onChange={handleSegmentChange} // Handle the onChange event
														input={<InputBase />}
													>
														<MenuItem value="">All</MenuItem>
														<MenuItem value="green"><i className="bi bi-circle-fill text-success me-2"></i> Send Only to Engaged</MenuItem>
														<MenuItem value="orange"><i className="bi bi-circle-fill text-warning me-2"></i> Send Only to Prospective</MenuItem>
														<MenuItem value="red"><i className="bi bi-circle-fill text-danger me-2"></i> Send Only to Un-Engaged</MenuItem>
													</Select>
													
													
												</div>
											</div>
										</div>
									)}	
										

									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												<h4 className="secondary-heading">Send to<b className="text-danger">*</b></h4>
												<h6 className="secondary-subheading">Select one or more email lists to send your campaign</h6>
											</div>
											<div className="col-6">
											{/* Select box */}
											
											{ExistselectedListNames ? (
												<div>
												


												{/* Display selected items */}
												<div className="selected-lists">
												{countsArray.map((id, index) => (
													<span key={id} >
													{namesArray[index]} ({countsArray[index]})
													</span>
												))}
												</div>
												<a href="#" className="link-button text-start " onClick={changeReciepents}>
													Change Recipients
												</a>
											</div>
											) : (
												<div>
													<Select
													className="input-form"
													sx={{ fontSize: '4vw' }}
													disableUnderline
													fullWidth
													value={selectedValue}
													onChange={handleSelectChange}
													input={<InputBase />}
													>
													<MenuItem value="">
														<em>None</em>
													</MenuItem>
													{menuItems.map((item) => (
														<MenuItem key={item.value} value={item.value}>
														{item.label} ({item.count})
														</MenuItem>
													))}
													</Select>

													{ErrorSelectList && <div className="error-message">{ErrorSelectList}</div>}

													{/* Display selected items */}
													<div className="selected-lists">
													{selectedItems.map((item) => (
														<span key={item.value}>
														{item.label} ({item.count}){" "}
														<i className="bi bi-x" onClick={() => removeSelectedItem(item)}></i>
														</span>
													))}
													</div>
												</div>
											)}


											{/* Inputs for selected list names and contact counts */}
											<input
												type="text"
												placeholder="List Ids"
												className="input-form d-none"
												value={selectedListIds}
												readOnly
											/>

											{/* Inputs for selected list names and contact counts */}
											<input
												type="text"
												placeholder="List Names"
												className="input-form d-none"
												value={selectedListNames}
												readOnly
											/>

											<input
												type="text"
												placeholder="Contact counts"
												className="input-form d-none"
												value={contactCounts}
												readOnly
											/>
											
											
											{/* Advanced options link */}
											<a href="#" className="link-button d-none" onClick={toggleContentTwo}>
												Advanced Options
											</a>
											</div>
										</div>
									</div>
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-lg-6"><h4 className="recipient-meter-left-heading"><span id="total_data_rel">{formatNumberWithCommas(totalContacts)} </span> recipients</h4></div>
											<div className="col-lg-6"><h4 className="recipient-meter-right-subheading"><span id="plan-credits">{formatNumberWithCommas(totalCredits)}</span> remaining emails</h4></div>
											
											<div className="col-lg-12">
											<div className="recipient-email-tracker">
											
											<div style={{ 
											maxWidth: '100%',	
											width: `${percentageUsed}%`,
											backgroundColor: totalContacts > CurrentAvailableCredits 
											? 'rgba(255, 0, 0, 1)' 
											: 'rgba(2, 96, 105, 1)',
											}}  id="total_email_progress_bar" className="recipient-email-tracker-fill"></div>
											
											</div>
											<p className="recipient-email-label">Select multiple lists and send them to as many recipients as you wish, within your plan limits</p>
											</div>
										</div>
									</div>
									
									

									
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-4">
											</div>
											<div className="col-8 text-end">
												
												<button type="button" onClick={handlePrev} className="secondary-button me-2">Prev</button>
												<button type="button" onClick={handleNext} className="primary-button ms-2">Next</button>
											</div>
										</div>
									</div>
								</div>
								)}
								
								{currentStep === 2 && (
								<div className="step-three">
									<div className="secondary-heading-area">
											<h4 className="secondary-heading">Content</h4>
												<h6 className="secondary-subheading">Add a subject & template for this campaign.</h6>
									</div>
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												<h4 className="secondary-heading">Subject <b className="text-danger">*</b></h4>
												<h6 className="secondary-subheading">Brief summary, aids recipient understanding and action.</h6>
											</div>
											<div className="col-6">
													<input type="text" ref={SubjectRef} className={`input-form w-100 ${SubjectError ? 'input-error' : ''}`} value={Subject} onChange={(e) => {
													setSubject(e.target.value); // Call a custom handler with the value
													}} placeHolder="" />
													
											{ErrorSubject && <div className="error-message">{ErrorSubject}</div>}
											</div>
										</div>
									</div>
									{AbType === "subject" && (
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												<h4 className="secondary-heading">Subject B <b className="text-danger">*</b></h4>
												<h6 className="secondary-subheading">Brief summary, aids recipient understanding and action.</h6>
											</div>
											<div className="col-6">
													<input type="text" ref={SubjectBRef} className={`input-form w-100 ${SubjectBError ? 'input-error' : ''}`} value={SubjectB} onChange={(e) => {
													setSubjectB(e.target.value); // Call a custom handler with the value
													}} placeHolder="" />
													
											{ErrorSubjectB && <div className="error-message">{ErrorSubjectB}</div>}
											</div>
										</div>
									</div>
									)}
									
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												<h4 className="secondary-heading">Preview <b className="text-danger d-none">*</b></h4>
												<h6 className="secondary-subheading">Snippet appearing with email subject line in inbox preview, giving a glimpse of email content.</h6>
											</div>
											<div className="col-6">
													<input type="text" ref={PreviewRef} className={`input-form w-100 ${PreviewError ? 'input-error' : ''}`} value={Preview} onChange={(e) => {
													setPreview(e.target.value); // Call a custom handler with the value
													}} placeHolder="" />
													
											{ErrorPreview && <div className="error-message">{ErrorPreview}</div>}
											</div>
										</div>
									</div>

									<input type="hidden" ref={TemplateRef} className={`input-form w-100 ${TemplateError ? 'input-error' : ''}`} value={Template} onChange={(e) => {
													setTemplate(e.target.value); // Call a custom handler with the value
													}} placeHolder="" />

									{AbType === "template" && (
									<input type="hidden" ref={TemplateBRef} className={`input-form w-100 ${TemplateBError ? 'input-error' : ''}`} value={TemplateB} onChange={(e) => {
											setTemplateB(e.target.value); // Call a custom handler with the value
											}} placeHolder="" />
									)}
									
													
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col">
											<Link to={`/email-templates?campaign_id=${CampaignId}`} className='text-decoration-none text-dark'>
												<h4 className="secondary-heading">Email Template <b className="text-danger">*</b></h4>
												<div id="email_preview_area" className="email-preview-area text-center">
													<img  src={
														Template
														? `/emailtemplates/${CookieAccountId}/${Template}/${Template}.png` // For existing Template
														: "https://app.aadow.com/images/placeholder-image.png"  // For empty Template
													} id="email_preview" className="email-preview" />
													<h6 className="text-center mt-3">Click here to select an email template</h6>
												</div>
												
											{ErrorTemplate && <div className="error-message">{ErrorTemplate}</div>}
											</Link>
											</div>
											{AbType === "template" && (
											<div className="col">
											<Link to={`/email-templates?campaign_id=${CampaignId}&template=b`} className='text-decoration-none text-dark'>
												<h4 className="secondary-heading">Email Template B <b className="text-danger">*</b></h4>
												<div id="email_preview_area" className="email-preview-area text-center">
													<img  src={
														TemplateB
														? `/emailtemplates/${CookieAccountId}/${TemplateB}/${TemplateB}.png` // For existing Template
														: "https://app.aadow.com/images/placeholder-image.png"  // For empty Template
													} id="email_preview" className="email-preview" />
													<h6 className="text-center mt-3">Click here to select an email template</h6>
												</div>
												
											{ErrorTemplateB && <div className="error-message">{ErrorTemplateB}</div>}
											</Link>
											</div>
											)}
										</div>
									</div>
									
									
									
									

									
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-4">
											</div>
											<div className="col-8 text-end">
												
												<button type="button" onClick={handlePrev} className="secondary-button me-2">Prev</button>
												<button type="button" onClick={handleNext} className="primary-button ms-2">Next</button>
											</div>
										</div>
									</div>
								</div>
								
								)}
								
								{currentStep === 3 && (
								
								<div className="step-four ">
									<div className="secondary-heading-area">
											<h4 className="secondary-heading">Review & Schedule</h4>
											<h6 className="secondary-subheading">Review campaign details and schedule it using your preferred method and time</h6>
									</div>
									
									
									
									
									
									<div className="form-area p-0">
									
										<div className="email-review-blocks">
											<div className="container-fluid">
										<div className="row">
											<div className="col-1 text-center icon-area">
											{GetSenderEmail !== "" ? (
												<i id="step_one_filled_icon" className="bi bi-check-circle-fill"></i>
											) : (
												<i id="step_one_empty_icon" className="bi bi-exclamation-circle-fill"></i>
											)}
											</div>
											
											<div className="col-9 center-area">
											<h3>Sender</h3>
											<h6>
												{GetSenderName && GetSenderName.trim() !== "" ? (
												<b id="final_sent_from_name">{GetSenderName}</b>
												) : (
												<b>---------</b>
												)}
  												<span> &#183;  </span> 
												
												{GetSenderEmail && GetSenderEmail.trim() !== "" ? (
												<span id="final_sent_from_email">{GetSenderEmail}</span>
												) : (
												<span>---------</span>
												)}
												</h6>
											</div>
											
											<div className="col-2 end-area text-center">
												{CampaignStatus !== "scheduled" && (
												<button id="edit_page_one" onClick={() => goToStep(0)} type="button">Edit</button>
												)}
											</div>
											
										</div>
										</div>
										</div>
										
										
										<div className="email-review-blocks">
											<div className="container-fluid">
											<div className="row ">
												<div className="col-1 text-center icon-area">
												{(GetContactLists !== "" && GetContactLists !== "") ? (
													<i id="step_two_filled_icon" className="bi bi-check-circle-fill"></i>
												) : (
													<i id="step_two_empty_icon" className="bi bi-exclamation-circle-fill"></i>
												)}
												</div>
												
												<div className="col-9 center-area">
												<h3>Recipients</h3>
												<h6>
												{GetContactLists && GetContactLists.trim() !== "" ? (
												<b id="final_total_recipients">{GetContactLists} </b>
												) : (
												<span>---------</span>
												)}
												<span> &#183;  </span> 
												{formatNumberWithCommas(totalCredits)} remaining emails
												</h6>
												</div>
												
												<div className="col-2 end-area text-center">
												{CampaignStatus !== "scheduled" && (
												<button id="edit_page_two" onClick={() => goToStep(1)} type="button">Edit</button>
												)}
												</div>
												
											</div>
											</div>
										</div>
										
										
										<div className="email-review-blocks">
											<div className="container-fluid">
											<div className="row ">
												<div className="col-1 text-center icon-area">
												{AbType === "template" ? (
												(GetSubjectLine !== "" && GetEmailTemplate !== "" && GetEmailTemplateB !== "") ? (
													<i id="step_one_filled_icon" className="bi bi-check-circle-fill"></i>
												) : (
													<i id="step_one_empty_icon" className="bi bi-exclamation-circle-fill"></i>
												)
												) : AbType === "subject" ? (
												(GetSubjectLine !== "" && GetSubjectLineB !== "" && GetEmailTemplate !== "") ? (
													<i id="step_one_filled_icon" className="bi bi-check-circle-fill"></i>
												) : (
													<i id="step_one_empty_icon" className="bi bi-exclamation-circle-fill"></i>
												)
												) : (
												(GetSubjectLine !== "" && GetEmailTemplate !== "") ? (
													<i id="step_one_filled_icon" className="bi bi-check-circle-fill"></i>
												) : (
													<i id="step_one_empty_icon" className="bi bi-exclamation-circle-fill"></i>
												)
												)}
												</div>
												
												<div className="col-9 center-area">
												<h3>Email Content</h3>
												<h6><b>Subject: </b>
													{GetSubjectLine && GetSubjectLine.trim() !== "" ? (
													<span id="final_sent_from_email">{GetSubjectLine}</span>
													) : (
													<span>---------</span>
													)}
												</h6>
												{AbType === "subject" && (
												<h6><b>Subject B: </b>
												{GetSubjectLineB && GetSubjectLineB.trim() !== "" ? (
												<span id="final_sent_from_email">{GetSubjectLineB}</span>
												) : (
												<span>---------</span>
												)}
												</h6>
												)}
												<h6><b>Preview: </b>
													{GetPreviewText && GetPreviewText.trim() !== "" ? (
													<span id="final_sent_from_email">{GetPreviewText}</span>
													) : (
													<span>---------</span>
													)}
												</h6>
												</div>
												
												<div className="col-2 end-area text-center">
												{CampaignStatus !== "scheduled" && (
													<button id="edit_page_three" onClick={() => goToStep(2)} type="button">
														Edit
													</button>
												)}
												</div>
												
											</div>
											<div className='row'>
												<div className='col'>
													<div className="email-preview-area">
													<center><img  src={
																Template
																? `/emailtemplates/${CookieAccountId}/${Template}/${Template}.png` // For existing Template
																: "https://app.aadow.com/images/placeholder-image.png"  // For empty Template
															} id="email_preview" className="email-preview" /></center>
													</div>
												</div>
												{AbType === "template" && (
												<div className='col'>
													<div className="email-preview-area">
													<center><img  src={
																TemplateB
																? `/emailtemplates/${CookieAccountId}/${TemplateB}/${TemplateB}.png` // For existing Template
																: "https://app.aadow.com/images/placeholder-image.png"  // For empty Template
															} id="email_preview" className="email-preview" /></center>
													</div>
												</div>
												)}
											</div>
										</div>
										
										
										
										</div>
										
										<h6 id="step_four_error" className="error mt-3">Please fill all required information</h6>
										
										
									</div>
									
									
									
									

									
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-4">
											</div>
											<div className="col-8 text-end">
											{CampaignStatus === "scheduled" ? (
											<button
												type="button"
												onClick={deleteScheduledEmailContact}
												className="primary-button bg-danger border-danger ms-2"
											>
												Suspend Campaign
											</button>
											) : AbType === "template" ? (
											GetSubjectLine && GetEmailTemplate && GetEmailTemplateB && GetContactLists && GetSenderName?.trim() ? (
												<button
												type="button"
												onClick={handleSchedule}
												className="primary-button ms-2"
												>
												Schedule
												</button>
											) : (
												<></> // Empty fallback for falsy conditions
											)
											) : AbType === "subject" ? (
											// Add your JSX for AbType === "subject" here
											GetSubjectLine && GetEmailTemplate && GetSubjectLineB && GetContactLists && GetSenderName?.trim() ? (
												<button
												type="button"
												onClick={handleSchedule}
												className="primary-button ms-2"
												>
												Schedule
												</button>
											) : (
												<></> // Empty fallback for falsy conditions
											)
											) : (
												GetSubjectLine && GetEmailTemplate && GetContactLists && GetSenderName?.trim() ? (
													<button
													type="button"
													onClick={handleSchedule}
													className="primary-button ms-2"
													>
													Schedule
													</button>
												) : (
													<></> // Empty fallback for falsy conditions
												)
											)}
											</div>
										</div>
									</div>
								</div>
								
								)}
								
								
								
								
							</div>
						</div>
						
						<div className="col-4">
							<div className="">
								<div className="preview-area">
								{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') && (
								<div>
								<div style={{ "marginTop": "20px", "paddingBottom": "0px", "textAlign": "center" }}>
									<div style={{ "display": "inline-flex" }}>
										<div style={{ "margin": "50px", "marginBottom": "0" }}>
											<ReactSpeedometer
												maxValue={100}
												value={cpuUsage}
												valueFormat={"d"}
												customSegmentStops={[0, 25, 50, 75, 100]}  // Defining the stops for each color
												segmentColors={[
													"#c0392b",   // Red
													"#e67e22",   // Orange
													"#f5b041",   // Yellow
													"#27ae60",   // Green
													"#dcdcdc"    // Light grey for the "border" effect after the last color
												]}
												currentValueText={spamMeterLabel}
												textColor={"black"}
											/>
										</div>
									</div>
								</div>
								<div className='line-breaker p-1'></div>
								</div>
								)}

								<div className="content">
								<div className="preview-device-area">
									<div className='mobile-phone'>
									<center><div className='brove'><span className='speaker'></span></div></center>
									<div className='screen'>
										<div className="container-fluid mobile-top-nav">
										<div className="row">
											<div className="col-4">
											<p className="ms-2">13:23</p>
											</div>
											<div className="col-4">
											</div>
											<div className="col-4 text-end">
											<i className="bi bi-wifi ms-1"></i>
											<i className="bi bi-reception-4 ms-1"></i>
											<i className="bi bi-battery-half ms-1"></i>
											</div>
										</div>
										</div>
										<div className="container-fluid mobile-top-nav-menu">
										<div className="row">
											<div className="col-4">
											<i className="bi bi-arrow-left ms-1"></i>
											</div>
											<div className="col-4 text-center">
											<h6>Inbox</h6>
											</div>
											<div className="col-4 text-end">
											<i className="bi bi-three-dots-vertical"></i>
											</div>
										</div>
										</div>
										<div className="container-fluid email-preview-list-area">
										
										<div className="row">
											<div className="col-2 pt-2">
											<span className="icon_avatar bg_blue_avatar"><i className="bi bi-person"></i></span>
											</div>
											<div className="col-8">
											{GetSenderName && GetSenderName.trim() !== "" ? (
												<h6 id="preview_sender_name" className="preview_sender_name">{GetSenderName}</h6>
											) : (
												<h6 id="preview_sender_name" className="preview_sender_name">---------</h6>
											)}
											{GetSubjectLine && GetSubjectLine.trim() !== "" ? (
												<h6 id="preview_sender_name" className="preview_sender_subject">{GetSubjectLine}</h6>
											) : (
												<h6 id="preview_sender_name" className="preview_sender_subject">---------</h6>
											)}
											
											{GetPreviewText && GetPreviewText.trim() !== "" ? (
												<h6 id="preview_sender_preview" className="preview_sender_preview">{GetPreviewText}</h6>
											) : (
												<h6 id="preview_sender_preview" className="preview_sender_preview">---------</h6>
											)}
											</div>
											<div className="col-2 pt-2">
											<h6 className="preview-time">10:35</h6>
											</div>
										</div>
										
										<div Style="opacity:0.5;"  className="row mt-3">
											<div className="col-2 pt-2">
											<span className="icon_avatar bg_red_avatar"><i className="bi bi-person"></i></span>
											</div>
											<div className="col-8">
											<h6 className="preview_sender_name">Aadow</h6>
											<h6 className="preview_sender_subject">Your Campaign Sent</h6>
											<p className="preview_sender_preview">This email to inform you that your campaign has been sent</p>
											</div>
											<div className="col-2 pt-2">
											<h6 className="preview-time">05:42</h6>
											</div>
										</div>
										
										</div>
										
									</div>
									</div>
								</div>
								<p className="email-preview-disclaimer">Actual email preview may vary depending on the email client.</p>
								</div>
								</div>
								</div>
						</div>
						
						
					</div>
				</div>
				
				
				
			</div>  
		</div>  
		)}
		{isSidenavOpen && <div className="overlay" onClick={handleOverlayClick}></div>}
		<div id="mySidenav" className={`sidenav_contact shadow-sm ${isSidenavOpen ? 'openedsidenav' : ''}`}>
	  <div id="add_contact_area" className="sidenav_content">
	  <div className="sidenav_top_area">
	  <div className="row align-items-center">
	  <div className="col-8">Schedule</div>
	  <div className="col-4"><a href="javascript:void(0)" className="closebtn" onClick={handleOverlayClick}>&times;</a></div>
	  </div>
	  </div>
	  
	  {totalContacts > CurrentAvailableCredits ? (
	  <div className='no-enough-creadit-area'>
		<h3>Not enough Credits</h3>
		<p>The campaign you would like to send requires <b>{totalContacts}</b> credits, and you have <b>{CurrentAvailableCredits}</b> credits left. Please try to purchase more credits or upgrade your plan.</p>
		<Link to="/select-plan">Upgrade</Link>
	  </div>
	  ):(
		<div className="sidenav_main_content">
		<div id="allow_campaign_scheduled" className="">
		<form>
			<div className="row">
			<div className="col-12">
				<label>
				Please provide the exact date and time you wish to send the campaign:
				</label>
			</div>

			<div className="col-12 mt-3">
				{/* Send Now Option */}
				<div
				id="send_now_campaign"
				onClick={() => setSelectedOption("send_now")}
				className={`schedule-option align-items-center row ${
					selectedOption === "send_now" ? "active" : ""
				}`}
				>
				<div className="col-2 pe-0 me-0">
					<img src="https://app.aadow.com/imgs/direct.png" alt="Send Now" />
				</div>
				<div className="col-10 ms-0 ps-3">
					<h3>Send now</h3>
					<h6>Send your campaign right now</h6>
				</div>
				</div>

				{/* Schedule Later Option */}
				<div
				id="schedule_later_campaign"
				onClick={() => setSelectedOption("schedule_later")}
				className={`schedule-option align-items-center row ${
					selectedOption === "schedule_later" ? "active" : ""
				}`}
				>
				<div className="col-2 pe-0 me-0">
					<img src="https://app.aadow.com/imgs/schedule.png" alt="Schedule Later" />
				</div>
				<div className="col-10 ms-0 ps-3">
					<h3>Schedule for later</h3>
					<h6>Set Date & Time for Campaign</h6>
				</div>
				</div>
			</div>
			</div>

			{/* Send Now Section */}
			{selectedOption === "send_now" && (
			<div className="row">
				<div className="col-5 mt-3">
				<button
					type="button"
					onClick={handleScheduleCampaignNow}
					className="final-schedule-button"
					disabled={loading}
				>
					{loading ? "Scheduling..." : "Send Now"}
				</button>
				</div>
			</div>
			)}

			{/* Schedule for Later Section */}
			{selectedOption === "schedule_later" && (
			<div id="schedule_area" className="row mt-0">
				<div className="col-12">
				<label>
					Date <span className="text-danger">*</span>
				</label>
				<input
				type="date"
				className="input-form w-100 mt-2"
				autoComplete="off"
				name="schedule_date"
				id="schedule_date"
				value={scheduleDate}
				onChange={handleDateChange}
				min={today} // Block all previous dates
			/>
				</div>

				<div className="col-md-4">
				<label>
					Time <span className="text-danger">*</span>
				</label>
				<Select
					labelId="hours-select-label"
					id="hours-select"
					className="select-box w-100"
					value={HoursSelectValue}
					onChange={HoursSelectChangeHandle}
				>
					{[...Array(24).keys()].map((hour) => (
					<MenuItem
						key={hour.toString().padStart(2, "0")}
						value={hour.toString().padStart(2, "0")}
						className="select-box-item"
					>
						<Box display="flex" alignItems="center">
						<Typography className="select-box-typography">
							{hour.toString().padStart(2, "0")}
						</Typography>
						</Box>
					</MenuItem>
					))}
				</Select>
				</div>

				<div className="col-md-4">
				<label>Minutes</label>
				<Select
					labelId="minutes-select-label"
					id="minutes-select"
					className="select-box w-100"
					value={MinutesSelectValue}
					onChange={MinutesSelectChangeHandle}
				>
					{[...Array(12).keys()].map((step) => {
					const minute = (step * 5).toString().padStart(2, "0");
					return (
						<MenuItem key={minute} value={minute} className="select-box-item">
						<Box display="flex" alignItems="center">
							<Typography className="select-box-typography">{minute}</Typography>
						</Box>
						</MenuItem>
					);
					})}
				</Select>
				</div>
				{ScheduledError && <div className="error-message mt-4">{ScheduledError}</div>}
				<div className="col-12 mt-4">
				<button
					type="button"
					onClick={handleScheduleCampaignLater}
					className="final-schedule-button"
					disabled={loading}
				>
					{loading ? "Scheduling..." : "Schedule"}
				</button>
				</div>
			</div>
			)}
		</form>
		</div>
	  </div>
	  )
	  }

	  

	  </div>
	  
	  
	  {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" ></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					
					<div className="modal-body pb-4 pt-0 px-4">
					
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="/iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Mail Scheduled!</h2>
							  <h2 className="secondary-description-modal text-center">Your mail has been scheduled successfully, Redirecting...</h2>
							</div>
						</div>
						
					</div>
					
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		</div>
       
    </SideBar>
  );
};

export default EmailApi;
